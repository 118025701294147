export default class StorageHandler {
    constructor() {
        this.expireTime = 1000 * 60 * 60 * 3;
    }

    /** 存储 localStorage **/
    setLocalStorage (key, value) {
        var valueDate = JSON.stringify({ val: value, timer: new Date().getTime() });
        try {
            localStorage.setItem(key, valueDate);
        } catch (e) {
            if (this.isQuotaExceeded(e)) {
                console.log("Error: 本地存储超过限制");
                localStorage.clear();
            } else {
                console.log("Error: 保存到本地存储失败");
            }
        }
    }

    /** 获取 localStorage **/
    getLocalStorage (key) {
        let newValue = null;
        if (localStorage.getItem(key)) {
            let dataObj = JSON.parse(localStorage.getItem(key));
            if (new Date().getTime() - dataObj.timer > this.expireTime) {
                console.log("存储已过期");
                localStorage.removeItem(key);
                return null;
            } else {
                newValue = dataObj.val;
            }
            return newValue;
        } else {
            return newValue;
        }
    }

    /** 删除 localStorage **/
    removeLocalStorage (key) {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    }

    /** 清空 localStorage **/
    clearLocalStorage() {
        localStorage.clear();
    }

    /** 存储 sessionStorage **/
    setSessionStorage(key, value) {
        var valueDate = JSON.stringify({ val: value, timer: new Date().getTime() });
        try {
            sessionStorage.setItem(key, valueDate);
        } catch (e) {
            // 兼容性写法
            if (this.isQuotaExceeded(e)) {
                console.log("Error: 本地存储超过限制");
                sessionStorage.clear();
            } else {
                console.log("Error: 保存到本地存储失败");
            }
        }
    }

    /** 获取 sessionStorage **/
    getSessionStorage(key) {
        let newValue = null;
        if (sessionStorage.getItem(key)) {
            var dataObj = JSON.parse(sessionStorage.getItem(key));
            if (new Date().getTime() - dataObj.timer > this.expireTime) {
                console.log("存储已过期");
                sessionStorage.removeItem(key);
                return null;
            } else {
                newValue = dataObj.val;
            }
            return newValue;
        } else {
            return null;
        }
    }

    /** 删除 sessionStorage **/
    removeSessionStorage(key) {
        if (sessionStorage.getItem(key)) {
            sessionStorage.removeItem(key);
        }
    }

    /** 清空 sessionStorage **/
    clearSessionStorage() {
        sessionStorage.clear();
    }

    // 判断是否超出存储大小
    isQuotaExceeded(e) {
        var quotaExceeded = false;
        if (e) {
            if (e.code) {
                switch (e.code) {
                    case 22:
                        quotaExceeded = true;
                        break;
                    case 1014: // Firefox
                        if (e.name === "NS_ERROR_DOM_QUOTA_REACHED") {
                            quotaExceeded = true;
                        }
                        break;
                }
            } else if (e.number === -2147024882) {
                // IE8
                quotaExceeded = true;
            }
        }
        return quotaExceeded;
    }
}
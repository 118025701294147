import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@a/css/common.scss'
document.documentElement.style.fontSize = document.documentElement.clientWidth / 24 + 'px';

Vue.config.productionTip = false;

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import common from "@/utils/common";
Vue.prototype.common = new common();

import StorageHandler from "@/utils/storageHandler";
Vue.prototype.storageHandler = new StorageHandler();

import language from "@/utils/language/index";
Vue.prototype.languageFilter = language;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    window.addEventListener('resize', () => document.documentElement.style.fontSize = document.documentElement.clientWidth / 24 + 'px');
  }
}).$mount("#app");
import axios from "./httpInterceptor";
export default class HttpHandler {
    constructor() {}
    get (url, params = {}, config = {}) {
        return axios.get(url, {params}, config);
    }
    post (url, data = {}, config = {}) {
        return axios.post(url, data, config);
    }
}

import store from "@/store";
import HttpHandler from "@/utils/httpHandler";
let httpHandler = new HttpHandler();
import StorageHandler from "@/utils/storageHandler";
let storageHandler = new StorageHandler();

export default class common {
    constructor() {
        this.filePrefix = 'https://sportapi.fun30.net/storage/'; // 静态文件前缀 测试
        this.currencyName = 'UU';
        this.secondCurrency = 'TT';
    }

    // 更新余额
    reBalance () {
        return Promise.all([
            new Promise((reslove, reject) => {
                httpHandler.get('/get-wallet-balance').then(res => {
                    let walletInfo = storageHandler.getSessionStorage('walletInfo') || {};
                    walletInfo.uu = res.data.balance;
                    walletInfo.tt = res.data.game_balance;
                    storageHandler.setSessionStorage('walletInfo', walletInfo);
                    reslove();
                });
            }),
            new Promise((reslove, reject) => {
                if (store.getters.loginSource == 2) {
                    httpHandler.get('/game/get-balance').then(res => {
                        let walletInfo = storageHandler.getSessionStorage('walletInfo') || {};
                        walletInfo.bnb = res.data.wallet.bnb;
                        walletInfo.qgt = res.data.wallet.qgt;
                        walletInfo.trx = res.data.wallet.trx;
                        walletInfo.usdt = res.data.wallet.usdt;
                        storageHandler.setSessionStorage('walletInfo', walletInfo);
                        reslove();
                    });
                } else {
                    httpHandler.get('/get-balance').then(res => {
                        let walletInfo = storageHandler.getSessionStorage('walletInfo') || {};
                        walletInfo.usdt = res.data.wallet.usdt;
                        storageHandler.setSessionStorage('walletInfo', walletInfo);
                        reslove();
                    });
                }
            })
        ]);
    }

    // 获取地址栏参数
    getQueryParams (key) {
        let params = window.location.href.split('?')[1];
        let result = '';
        if (params) {
            let asd = params.split('&')
            for (let i=0; i<asd.length; i++) {
                let lsP = asd[i].split('=');
                if ([lsP[0]] == key) {
                    result = lsP[1];
                }
            }
        }
        return result;
    }

    // 限制两位小数
    verifyDecimal2 (val) {
        if (val) {
            if (!/^(\d+)(\.\d{1,2})?$/.test(val.toString())) {
                return `${val.split('.')[0]}.${val.split('.')[1].slice(0, 2)}`;
            } else {
                return val;
            }
        } else {
            return '';
        }
    }
}

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "index",
  mounted() {
    // 每十秒更新用户余额信息
    if (this.storageHandler.getSessionStorage('token')) {
      this.common.reBalance();
    }
    setInterval(() => {
      if (this.storageHandler.getSessionStorage('token')) this.common.reBalance();
    },10000);
  }
}
</script>

<style lang="scss">
  //body { background: #ccc;}
</style>
import zhCN from "@/utils/language/zh-cn";
import zhHk from "@/utils/language/zh-hk";
import en from "@/utils/language/en";
import ja from "@/utils/language/ja";
import store from "@/store";

export default (val) => {
    if (store.getters.language == 'zh-CN') {
        return zhCN[val] || '简体无语言包';
    } else if (store.getters.language == 'zh-TW') {
        return zhHk[val] || '繁體無語言包';
    } else if (store.getters.language == 'en') {
        return en[val] || 'English No language pack';
    } else if (store.getters.language == 'ja') {
        return ja[val] || 'Japanese No language pack';
    } else {
        return '';
    }
};
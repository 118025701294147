export default {
    '暂无数据': '仮無数の根拠です',
    '没有更多了': 'これ以上ありません',
    'Dialog确认': '確認します',
    'Dialog取消': '取り消します',
    '语言切换-简体版': '简体版',
    '语言切换-繁體版': '繁體版',
    '语言切换-ENGLISH': 'English',
    '语言切换-JAPANESE': '日本語.',
    '其它': 'その他です',
    '锦标赛奖池': '選手権大会の表彰池',

    // 登录
    '登录-电子邮箱/用户名': 'eメール/ユーザー名です',
    '登录-请填写电子邮箱/用户名': 'eメール/ユーザー名をお願いします。',
    '登录-密码': 'パスワードです',
    '登录-请填写密码': 'パスワードをお願いします。',
    '登录-登入': 'ログインします',
    '登录-去注册': '登録します',
    '登录-忘记密码': 'パスワードを忘れました？',
    '登录-提示-登录中': '登録中です...',
    '登录-我们推荐使用以下浏览器以获得最佳使用体验': '最高の利用体験を得るために、以下のブラウザをお勧めします。',
    '登录-如果您在使用本网站遇到任何问题，请与我们联系': '当サイトのご利用で何か問題がございましたら、ご連絡下さい。',

    // 注册
    '注册-登入': 'ログインします',
    '注册-用户名': 'ユーザー名です',
    '注册-请填写用户名': 'ユーザー名をお願いします。',
    '注册-用户名格式错误': 'ユーザー名は6 ~ 16桁のアルファベットまたは数字で構成されています。',
    '注册-密码': 'パスワードです',
    '注册-请填写密码': 'パスワードをお願いします。',
    '注册-密码由8~18位字符、数字、特殊符号组成': '暗号は,8~18桁の文字,数字,特殊記号でできています',
    '注册-电子邮箱': 'eメールです',
    '注册-请填写电子邮箱': 'eメールをお願いします。',
    '注册-请输入正确的电子邮箱': '正しいeメールの入力をお願いします。',
    '注册-邮箱验证码': 'eメール認証コードです',
    '注册-请填写邮箱验证码': 'eメール認証コードをお願いします。',
    '注册-发送验证码': 'captchaを送ります',
    '注册-验证码发送成功': '送信成功です！',
    '注册-姓名': '名前です',
    '注册-请填写姓名': 'お名前お願いします',
    '注册-邀请码': '招待コード(任意記入)です。',
    '注册-注册': '登録します',
    '注册-注册成功，即将跳转登录页面...': '登録に成功しました。間もなくログインページに遷移します。',
    '注册-我们推荐使用以下浏览器以获得最佳使用体验': '最高の利用体験を得るために、以下のブラウザをお勧めします。',
    '注册-如果您在使用本网站遇到任何问题，请与我们联系': '当サイトのご利用で何か問題がございましたら、ご連絡下さい。',

    // 忘记密码
    '忘记密码-忘记密码': 'パスワードを忘れました',
    '忘记密码-您的密码必须根据以下规则:': 'パスワードは以下のルールに従います。',
    '忘记密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.パスワードは8~18桁の文字、数字、特殊な記号でできています。',
    '忘记密码-电子邮箱': 'eメールです',
    '忘记密码-请填写电子邮箱': 'eメールをお願いします。',
    '忘记密码-请输入正确的电子邮箱': '正しいeメールの入力をお願いします。',
    '忘记密码-邮箱验证码': 'eメール認証コードです',
    '忘记密码-发送验证码': 'captchaを送ります',
    '忘记密码-请填写邮箱验证码': 'eメール認証コードをお願いします。',
    '忘记密码-验证码发送成功': 'captcha送信成功です',
    '忘记密码-新密码': '新しいパスワードです',
    '忘记密码-请填写新密码': '新しいパスワードをお願いします。',
    '忘记密码-密码由8~18位字符、数字、特殊符号组成': '暗号は,8~18桁の文字,数字,特殊記号でできています',
    '忘记密码-确认新密码': '新しいパスワードを確認します',
    '忘记密码-请填写确认新密码': '新しいパスワードの確認をお願いします。',
    '忘记密码-与新密码不一致': '新しいパスワードと一致しません',
    '忘记密码-取消': '取り消します',
    '忘记密码-提交': '提出します',
    '忘记密码-修改成功': '修正成功です',

    // 顶部菜单
    '顶部菜单-今日': '今日です',
    '顶部菜单-早盘': '朝盤です',
    '顶部菜单-团队': 'チーム',
    '顶部菜单-盈利报表': '利益報告書です',
    '顶部菜单-锦标赛': '選手権大会',
    '顶部菜单-余额': '残額です',
    '顶部菜单-提现': 'キャッシュバックです',
    '顶部菜单-邀请码': '招待コードです',
    '顶部菜单-数字钱包': 'デジタルウォレットです',
    '顶部菜单-子-钱包充值': '財布チャージです',
    '顶部菜单-子-钱包提现': '財布キャッシュアウト',
    '顶部菜单-子-钱包记录': '財布の記録です',
    '顶部菜单-子-兑换': '両替する',
    '顶部菜单-子-UU消费记录': 'UU 消費履歴です',
    '顶部菜单-子-TT消费记录': 'TT 消費履歴です',
    '顶部菜单-修改密码': 'パスワードを変更します',
    '顶部菜单-修改邮箱绑定': 'メールバインディングの変更です',
    '顶部菜单-公告': '公告します',
    '顶部菜单-联系我们': '連絡してきます',
    '顶部菜单-玩法规则': '遊び方のルールです',
    '顶部菜单-语言切换': '言葉の切り替えです',
    '顶部菜单-退出': '脱退します',

    // 底部菜单
    '底部菜单-我的赛事': '私のレースです',
    '底部菜单-赛程': 'レース日程です',
    '底部菜单-注单': '注記します',
    '底部菜单-坐庄记录': '座荘記録です',
    '底部菜单-投注记录': '賭けの記録です',

    // 邀请
    '邀请-邀请好友立即获得高额收益返佣': '友人を招待すれば即高額リターン',
    '邀请-邀请码': '招待コードです',
    '邀请-复制链接': 'リンクをコピーします',
    '邀请-复制成功': '複製成功です',

    // 钱包 - 钱包充值
    '钱包-钱包充值-钱包充值': '財布チャージです',
    '钱包-钱包充值-您的存款地址': 'ご入金先のご住所です：',
    '钱包-钱包充值-复制': '複製します',
    '钱包-钱包充值-复制成功': 'コピー成功です!',
    '钱包-钱包充值-最低存款100USDT，较低的金额可能不会被记入，或被退款。': '最低預金は100USDTで、それ以下の金額は入金されないか、返金される可能性があります。',

    // 钱包 - 钱包提现
    '钱包-钱包提现-提现': '財布キャッシュアウト',
    '钱包-钱包提现-选择币种': '貨幣を選びます',
    '钱包-钱包提现-选择网络': 'ネットワークを選択します',
    '钱包-钱包提现-您的提款地址': 'お引き出しの住所です：',
    '钱包-钱包提现-请输入提款地址': '引き出し先の入力をお願いします。',
    '钱包-钱包提现-数量': '数です',
    '钱包-钱包提现-请输入数量': '数量の入力をお願いします。',
    '钱包-钱包提现-余额': '残額です',
    '钱包-钱包提现-最低存款100USDT，较低的金额可能不会被记入，或被退款。': '最低預金は100USDTで、それ以下の金額は入金されないか、返金される可能性があります。',
    '钱包-钱包提现-确认': '確認します',
    '钱包-钱包提现-网络交易需要0-2小时，且网络确认不包括在内': 'ネット取引には0 ~ 2時間かかり、ネットでの確認は対象外です。',
    '钱包-钱包提现-提交成功': '提出できました!',

    // 钱包 - 钱包记录
    '钱包-钱包记录-钱包记录': '財布の記録です',
    '钱包-钱包记录-选择类型': 'タイプを選びます',
    '钱包-钱包记录-全部': '全部です',
    '钱包-钱包记录-充值': 'チャージします',
    '钱包-钱包记录-提现': 'キャッシュバックです',
    '钱包-钱包记录-兑换': '両替する',
    '钱包-钱包记录-选择开始日期': '開始日を選択します。',
    '钱包-钱包记录-选择结束日期': '終了日を選びます',
    '钱包-钱包记录-币种': '貨幣種です',
    '钱包-钱包记录-网络': 'インターネットです',
    '钱包-钱包记录-类型': 'タイプです',
    '钱包-钱包记录-金额': '金額です',
    '钱包-钱包记录-手续费': '手数料です',
    '钱包-钱包记录-状态': '状態です',
    '钱包-钱包记录-确认中': '確認中です',
    '钱包-钱包记录-已确认': '確認しました',
    '钱包-钱包记录-充值地址': 'チャージアドレスです。',
    '钱包-钱包记录-充值时间': 'チャージ時間です:',
    '钱包-钱包记录-提现地址': 'お引き出し先:そうですね。',
    '钱包-钱包记录-到账时间': '入金時間です。',
    '钱包-钱包记录-描述': '説明します:',
    '钱包-钱包记录-时间': '時間です:',

    // 钱包 - 兑换
    '钱包-兑换-兑换': '両替する',
    '钱包-兑换-USDT兑换UU': 'USDTはUUを交換します',
    '钱包-兑换-UU兑换USDT': 'UUはUSDTに交換します',
    '钱包-兑换-USDT兑换TT': 'TTをUSDTに交換します',
    '钱包-兑换-TT兑换USDT': 'TTはUSDTに交換します',
    '钱包-兑换-请输入数量': '数量入力お願いします!',
    '钱包-兑换-余额': '残高です。',
    '钱包-兑换-提交成功': '提出成功です',

    // 钱包 - TT消费记录
    '钱包-TT消费记录-消费记录': 'TT 消費履歴です',
    '钱包-TT消费记录-选择类型': 'タイプを選びます',
    '钱包-TT消费记录-选择开始日期': '開始日を選択します。',
    '钱包-TT消费记录-选择结束日期': '終了日を選びます',
    '钱包-TT消费记录-时间': '時間です',
    '钱包-TT消费记录-类型': 'タイプです',
    '钱包-TT消费记录-金额': '金額です',
    '钱包-TT消费记录-余额': '残額です',
    '钱包-TT消费记录-描述': '説明します:',
    '钱包-TT消费记录-订单': 'ご註文です。',

    // 修改密码
    '修改密码-修改密码': 'パスワードを変更します',
    '修改密码-您的密码必须根据以下规则:': 'パスワードは以下のルールに従います。',
    '修改密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.パスワードは8~18桁の文字、数字、特殊な記号でできています。',
    '修改密码-旧密码': '古いパスワードです',
    '修改密码-请填写旧密码': '旧パスワードをお願いします。',
    '修改密码-新密码': '新しいパスワードです',
    '修改密码-请填写新密码': '新しいパスワードをお願いします。',
    '修改密码-密码由8~18位字符、数字、特殊符号组成': '暗号は,8~18桁の文字,数字,特殊記号でできています',
    '修改密码-取消': '取り消します',
    '修改密码-提交': '提出します',
    '修改密码-修改成功': '修正できました!',

    // 修改绑定邮箱
    '修改绑定邮箱-修改绑定邮箱': 'メールボックスを修正します。',
    '修改绑定邮箱-您的邮箱必须根据以下规则:': 'あなたのメールアドレスは以下のルールに準拠しなければなりません。',
    '修改绑定邮箱-1.验证码有效期 10min。': '1. captchaの有効期間は10minです。',
    '修改绑定邮箱-2.每个验证码只能验证一次。': '2. captchaは1回限りです。',
    '修改绑定邮箱-旧邮箱': '古いメールボックスです',
    '修改绑定邮箱-新的邮箱': '新しいメールです',
    '修改绑定邮箱-请填写新的邮箱': '新しいメールアドレスをお願いします。',
    '修改绑定邮箱-请输入正确的电子邮箱': '正しいeメールの入力をお願いします。',
    '修改绑定邮箱-邮箱验证码': 'eメール認証コードです',
    '修改绑定邮箱-请填写邮箱验证码': 'eメール認証コードをお願いします。',
    '修改绑定邮箱-发送验证码': 'captchaを送ります',
    '修改绑定邮箱-验证码发送成功': 'captcha送信成功です',
    '修改绑定邮箱-取消': '取り消します',
    '修改绑定邮箱-提交': '提出します',
    '修改绑定邮箱-修改成功': '修正できました!',

    // 公告
    '公告-公告': '公告します',
    '公告-一般': '普通です',
    '公告-重要': '大切です',
    '公告-个人': '個人です',
    '公告-全部': '全部です',
    '公告-今日': '今日です',
    '公告-昨日': '昨日です',

    // 联系我们
    '联系我们-联系我们': '連絡先',
    '联系我们-联系邮箱': '連絡先メールボックス',

    // 玩法规则
    '玩法规则-玩法规则': '遊び方のルール',

    // 首页
    '首页-今日赛事': '今日の試合です',
    '首页-早盘赛事': '朝の試合です',

    // 今日
    '今日-热门赛事': '人気レースです',

    // 早盘
    '早盘-热门赛事': '人気レースです',
    '早盘-所有日期': 'すべての日付です',
    '早盘-星期一': '月曜日です',
    '早盘-星期二': '火曜日です',
    '早盘-星期三': '水曜日です',
    '早盘-星期四': '木曜日です',
    '早盘-星期五': '金曜日です',
    '早盘-星期六': '土曜日です',
    '早盘-星期日': '日曜日です',

    // 团队
    '团队-返佣总额': '差し戻し総額',
    '团队-团队人数': 'チーム人数',
    '团队-人': '人',
    '团队-一级': '1レベル',
    '团队-二级': '2レベル',
    '团队-三级': '3レベル',
    '团队-选择开始日期': '開始日を選択します。',
    '团队-选择结束日期': '終了日を選びます',
    '团队-会员名称': '会員名',
    '团队-推广返佣': '奉還を広める',
    '团队-推广分成': '分布を広める',
    '团队-详情': '詳細',
    // 团队详情
    '团队详情 - 团队详情': '团队详情',
    '团队详情-选择开始日期': '開始日を選択します。',
    '团队详情-选择结束日期': '終了日を選びます',
    '团队详情-会员ID': '会員ID',
    '团队详情-类型': 'タイプです',
    '团队详情-推广分成': '分布を広める',
    '团队详情-推广返佣': '奉還を広める',

    // 盈利报表
    '盈利报表-坐庄报表': '配当報告書',
    '盈利报表-投注报表': '賭けのレポートです',
    '盈利报表-合计报表': '集計表です。',
    // 盈利报表 - 坐庄报表
    '盈利报表-坐庄报表-选择周期': '選択期間です',
    '盈利报表-坐庄报表-选择开始日期': '開始日を選択します。',
    '盈利报表-坐庄报表-选择结束日期': '終了日を選びます',
    '盈利报表-坐庄报表-时间': '時間です',
    '盈利报表-坐庄报表-坐庄ID': 'オーナーIDです',
    '盈利报表-坐庄报表-投注金额': '賭け金額です',
    '盈利报表-坐庄报表-有效金额': '有効金額です',
    '盈利报表-坐庄报表-手续费': '手数料です',
    '盈利报表-坐庄报表-输赢': '勝ち負けです',
    '盈利报表-坐庄报表-笔数': 'ペンの数です',
    '盈利报表-坐庄报表-总计': '総計です',
    // 盈利报表 - 坐庄报表 - 投注记录
    '盈利报表-坐庄报表-投注记录-坐庄投注记录': '賭けの記録です',
    '盈利报表-坐庄报表-投注记录-选择开始日期': '開始日を選択します。',
    '盈利报表-坐庄报表-投注记录-选择结束日期': '終了日を選びます',
    '盈利报表-坐庄报表-投注记录-足球': 'サッカーです',
    '盈利报表-坐庄报表-投注记录-下注金额': '賭けた金額です。',
    '盈利报表-坐庄报表-投注记录-结果': '結果です:',
    '盈利报表-坐庄报表-投注记录-总计': '総計です',
    '盈利报表-坐庄报表-投注记录-下注总计': '賭けの合計です:',
    '盈利报表-坐庄报表-投注记录-结果总计': '結果は合計します:',
    '盈利报表-坐庄报表-投注记录-返回顶部': 'トップに戻ります',
    // 盈利报表 - 坐庄报表 - 详情
    '盈利报表-坐庄报表-详情-坐庄报表': '配当報告書',
    '盈利报表-坐庄报表-详情-选择开始日期': '開始日を選択します。',
    '盈利报表-坐庄报表-详情-选择结束日期': '終了日を選びます',
    '盈利报表-坐庄报表-详情-保证金额': '保証します金額',
    '盈利报表-坐庄报表-详情-投注金额': '賭け金額です',
    '盈利报表-坐庄报表-详情-输赢': '勝ち負けです',
    '盈利报表-坐庄报表-详情-保证金': '保証金です',
    '盈利报表-坐庄报表-详情-门票价': 'チケット代です',
    '盈利报表-坐庄报表-详情-总投注金额': '賭けた金額の合計です',
    '盈利报表-坐庄报表-详情-笔': 'ペンです',
    '盈利报表-坐庄报表-详情-玩家输赢': 'プレイヤー勝負です',
    '盈利报表-坐庄报表-详情-奖池手续费': 'プール手数料です',
    '盈利报表-坐庄报表-详情-奖池余额': 'プール残高です',
    '盈利报表-坐庄报表-详情-奖池输赢': 'プール勝負です',

    // 盈利报表 - 投注报表
    '盈利报表-投注报表-选择周期': '選択期間です',
    '盈利报表-投注报表-今日': '今日です',
    '盈利报表-投注报表-昨日': '昨日です',
    '盈利报表-投注报表-一周内': '一週間以内にです',
    '盈利报表-投注报表-一月内': '1か月以内にです',
    '盈利报表-投注报表-选择开始日期': '開始日を選択します。',
    '盈利报表-投注报表-选择结束日期': '終了日を選びます',
    '盈利报表-投注报表-投注金额': '賭け金額です',
    '盈利报表-投注报表-有效金额': '有効金額です',
    '盈利报表-投注报表-输赢': '勝ち負けです',
    '盈利报表-投注报表-日期': '期日です',
    // 盈利报表 - 投注报表 - 详情
    '盈利报表-投注报表-详情-选择时间': '時間を選びます',
    '盈利报表-投注报表-详情-足球': 'サッカーです',
    '盈利报表-投注报表-详情-下注金额': '賭けた金額です。',
    '盈利报表-投注报表-详情-结果': '結果です:',
    '盈利报表-投注报表-详情-总计': '総計です',
    '盈利报表-投注报表-详情-下注总计': '賭けの合計です:',
    '盈利报表-投注报表-详情-结果总计': '結果は合計します:',
    '盈利报表-投注报表-详情-返回顶部': 'トップに戻ります',

    // 盈利报表 - 合计报表
    '盈利报表-合计报表-开始日期': '開始日です',
    '盈利报表-合计报表-结束日期': '終了期日',
    '盈利报表-合计报表-日期': '期日です',
    '盈利报表-合计报表-投注赢输': '賭けに勝つか負けるかです',
    '盈利报表-合计报表-坐庄赢输': '勝ちますか負けますか',
    '盈利报表-合计报表-总计': '総計です',

    // 我的赛事
    '我的赛事-我的赛事': '私のレースです',
    '我的赛事-投注': '賭けます',
    '我的赛事-坐庄': '座り込みです',
    '我的赛事-取消收藏': 'コレクションをキャンセルします。',
    '我的赛事-确认是否取消收藏当前比赛': '現在の試合のコレクションをキャンセルするかどうか確認しますか?',
    '我的赛事-已取消收藏': 'コレクションをキャンセルしました!',

    // 赛程
    '赛程-赛程': 'レース日程です',
    '赛程-所有日期': 'すべての日付です',
    '赛程-星期一': '月曜日です',
    '赛程-星期二': '火曜日です',
    '赛程-星期三': '水曜日です',
    '赛程-星期四': '木曜日です',
    '赛程-星期五': '金曜日です',
    '赛程-星期六': '土曜日です',
    '赛程-星期日': '日曜日です',
    '赛程-投注': '賭けます',
    '赛程-坐庄': '座り込みです',
    '赛程-收藏': 'コレクションします',
    '赛程-取消收藏': 'コレクションをキャンセルします。',
    '赛程-确认是否收藏当前比赛': '現在の試合のコレクションを確認しますか?',
    '赛程-收藏成功': 'コレクション成功です!',
    '赛程-确认是否取消收藏当前比赛': '現在の試合のコレクションをキャンセルするかどうか確認しますか?',
    '赛程-已取消收藏': 'コレクションをキャンセルしました!',

    // 注单
    '注单-注单': '注記します',
    '注单-足球': 'サッカーです',
    '注单-请输入下注金额': '賭け金の入力をお願いします。',
    '注单-最大投注金额': '賭け金額最大です',
    '注单-最小投注金额': '賭けの最小額です',
    '注单-下注总额': '賭け総額です',
    '注单-预估可赢': '見込みで勝てます',
    '注单-下注': '賭けます',
    '注单-下注中': '賭けます…',
    '注单-下注成功': 'ベッティング成功です',
    '注单-下注失败': '賭けに失敗する',
    '注单-确认': '確認します',

    // 坐庄记录
    '坐庄记录-坐庄记录': '座荘記録です',
    '坐庄记录-普通': '普通です',
    '坐庄记录-比赛': '試合です',
    '坐庄记录-奖池总额': 'プール総額です:',
    '坐庄记录-下注总额': '賭け総額です:',
    '坐庄记录-笔': 'ペンです',
    '坐庄记录-撤销': '撤回です',
    '坐庄记录-确认是否撤销': '撤回を確認しますか?',
    '坐庄记录-已撤销': '撤回しました',
    '坐庄记录-保证金': '保証金です',
    '坐庄记录-最低投注': '賭けは最低です',
    '坐庄记录-总投注金额': '賭けた金額の合計です',
    '坐庄记录-胜负平': '勝負は引き分けです',
    '坐庄记录-主': '主です',
    '坐庄记录-和': 'とです',
    '坐庄记录-客': 'お客さんです',
    '坐庄记录-波胆': '波胆です',
    '坐庄记录-让球': 'ボールを譲ります',
    '坐庄记录-大小球': '大きなボールです',
    '坐庄记录-大': '大きいです',
    '坐庄记录-小': '小さいです',

    // 投注记录
    '投注记录-投注记录': '賭けの記録です',
    '投注记录-普通': '普通です',
    '投注记录-比赛': '試合です',
    '投注记录-笔': 'ペンです',
    '投注记录-投注金额': '賭けの金額です。',
    '投注记录-结果': '結果です:',
    '投注记录-足球': 'サッカーです',

    // 比赛列表
    '比赛列表-比赛列表': '試合一覧です',
    '比赛列表-投注': '賭けます',
    '比赛列表-坐庄': '座り込みです',
    '比赛列表-收藏': 'コレクションします',
    '比赛列表-取消收藏': 'コレクションをキャンセルします。',
    '比赛列表-确认是否收藏当前比赛': '現在の試合のコレクションを確認しますか?',
    '比赛列表-收藏成功': 'コレクション成功です!',
    '比赛列表-确认是否取消收藏当前比赛': '現在の試合のコレクションをキャンセルするかどうか確認しますか?',
    '比赛列表-已取消收藏': 'コレクションをキャンセルしました!',

    // 坐庄
    '坐庄-保证金额': '保証します金額',
    '坐庄-最低投注': '賭けは最低です',
    '坐庄-创建': '創り出します',
    '坐庄-编辑': '編集します',
    '坐庄-删除': '削除します',
    '坐庄-平局': '引き分けです',
    '坐庄-和局': '和局です',
    '坐庄-胜负平': '勝負は引き分けです',
    '坐庄-波胆': '波胆です',
    '坐庄-让球': 'ボールを譲ります',
    '坐庄-大小球': '大きなボールです',
    '坐庄-确认': '確認します',
    '坐庄-请输入': '入力お願いします',
    '坐庄-设置赔率': 'オッズを設定します。',
    '坐庄-请输入设置赔率': 'オッズ設定入力お願いします!',
    '坐庄-胜': '勝ちます',
    '坐庄-让': 'ジャンです',
    '坐庄-大': '大きいです',
    '坐庄-小': '小さいです',
    '坐庄-提交成功': '提出できました!',

    // 投注列表
    '投注列表-投注列表': '賭けリストです',
    '投注列表-胜负平': '勝負は引き分けです',
    '投注列表-主': '主です',
    '投注列表-和': 'とです',
    '投注列表-客': 'お客さんです',
    '投注列表-波胆': '波胆です',
    '投注列表-让球': 'ボールを譲ります',
    '投注列表-大小球': '大きなボールです',
    '投注列表-大': '大きいです',
    '投注列表-小': '小さいです',
    '投注列表-现货量': '現物量です。',
    '投注列表-最低投注': '賭けの最低値です',
    '投注列表-投注': '賭けます',
    '投注列表-足球': 'サッカーです',
    '投注列表-请输入下注金额': '賭け金の入力をお願いします。',
    '投注列表-下注金额': 'ベッティング金額です',
    '投注列表-设置金额': '設定金額です',
    '投注列表-可赢金额': '獲得可能な金額です',
    '投注列表-加到下注单': 'ベッティングシートに追加します',
    '投注列表-下注': '賭けます',
    '投注列表-您已投注成功': 'あなたは賭けに成功しました!',
    '投注列表-确认': '確認します',
    '投注列表-提交成功': '提出成功です',

    // 比赛
    '比赛-锦标赛列表': '選手権大会リスト',
    '比赛-奖池金额': 'プール金額です。',
    '比赛-收益': '収益です',
    '比赛-排名': '順位です',
    '比赛-所有日期': 'すべての日付です',
    '比赛-星期一': '月曜日です',
    '比赛-星期二': '火曜日です',
    '比赛-星期三': '水曜日です',
    '比赛-星期四': '木曜日です',
    '比赛-星期五': '金曜日です',
    '比赛-星期六': '土曜日です',
    '比赛-星期日': '日曜日です',
    '比赛-投注': '賭けます',
    '比赛-坐庄': '座り込みです',

    // 比赛 - 排名
    '比赛排名-排名': '順位です',

    // 比赛 - 坐庄
    '比赛坐庄-保证金额': '保証します金額',
    '比赛坐庄-最低投注': '賭けは最低です',
    '比赛坐庄-创建': '創り出します',
    '比赛坐庄-编辑': '編集します',
    '比赛坐庄-删除': '削除します',
    '比赛坐庄-平局': '引き分けです',
    '比赛坐庄-和局': '和局です',
    '比赛坐庄-胜负平': '勝負は引き分けです',
    '比赛坐庄-确认': '確認します',
    '比赛坐庄-请输入': '入力お願いします',
    '比赛坐庄-设置赔率': 'オッズを設定します。',
    '比赛坐庄-请输入设置赔率': 'オッズ設定入力お願いします!',
    '比赛坐庄-胜': '勝ちます',
    '比赛坐庄-提交成功': '提出できました!',

    // 比赛 - 投注
    '比赛投注-投注列表': '賭けリストです',
    '比赛投注-胜负平': '勝負は引き分けです',
    '比赛投注-主': '主です',
    '比赛投注-和': 'とです',
    '比赛投注-客': 'お客さんです',
    '比赛投注-现货量': '現物量です。',
    '比赛投注-最低投注': '賭けの最低値です',
    '比赛投注-投注': '賭けます',
    '比赛投注-足球': 'サッカーです',
    '比赛投注-请输入下注金额': '賭け金の入力をお願いします。',
    '比赛投注-下注金额': 'ベッティング金額です',
    '比赛投注-设置金额': '設定金額です',
    '比赛投注-可赢金额': '獲得可能な金額です',
    '比赛投注-下注': '賭けます',
    '比赛投注-您已投注成功': 'あなたは賭けに成功しました!',
    '比赛投注-确认': '確認します',
    '比赛投注-提交成功': '提出成功です',
}
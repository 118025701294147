const common = {
    state: {
        language: 'zh-CN',
        bettingCart: [],
        loginSource: 1,
        profitReportType: ''
    },
    mutations: {
        // 语言包类型设置
        SET_LANGUAGE (state, data) {
            state.language = data;
        },
        // 注单列表 - 添加一条
        SET_BETTINGCART (state, data) {
            data.sort = state.bettingCart.length + 1;
            data.isComplete = false;
            data.completeStatus = false;
            data.completeMsg = '';
            state.bettingCart.push(data);
        },
        // 注单列表 - 删除一条
        DEL_BETTINGCART (state, data) {
            state.bettingCart = state.bettingCart.filter(item => item.sort != data).map((item, index) => {
                item.sort = index + 1;
                return item;
            });
        },
        // 注单列表 - 更新
        UPDATA_BETTINGCART (state, data) {
            data.sort = state.bettingCart.length + 1;
            state.bettingCart = data;
        },
        // 注单列表 - 清空
        CLEAR_BETTINGCART (state, data) {
            state.bettingCart = [];
        },
        // 设置登录来源
        SET_LOGINSOURCE (state, data) {
            state.loginSource = data;
        },
        // 设置盈利报表类型
        SET_PROFITREPORTTYPE (state, data) {
            state.profitReportType = data;
        }
    }
}

export default common;
export default {
    '暂无数据': '暫無數據',
    '没有更多了': '沒有更多了',
    'Dialog确认': '確認',
    'Dialog取消': '取消',
    '语言切换-简体版': '简体版',
    '语言切换-繁體版': '繁體版',
    '语言切换-ENGLISH': 'English',
    '语言切换-JAPANESE': 'Japanese',
    '其它': '其它',
    '锦标赛奖池': '錦標賽獎池',

    // 登录
    '登录-电子邮箱/用户名': '電子郵箱/用戶名',
    '登录-请填写电子邮箱/用户名': '請填寫電子郵箱/用戶名',
    '登录-密码': '密碼',
    '登录-请填写密码': '請填寫密碼',
    '登录-登入': '登入',
    '登录-去注册': '去註冊',
    '登录-忘记密码': '忘記密碼？',
    '登录-提示-登录中': '登錄中...',
    '登录-我们推荐使用以下浏览器以获得最佳使用体验': '我們推薦使用以下瀏覽器以獲得最佳使用體驗',
    '登录-如果您在使用本网站遇到任何问题，请与我们联系': '如果您在使用本網站遇到任何問題，請與我們聯繫',

    // 注册
    '注册-登入': '登入',
    '注册-用户名': '用戶名',
    '注册-请填写用户名': '請填寫用戶名',
    '注册-用户名格式错误': '用戶名由6～16位字母或數字組成',
    '注册-密码': '密碼',
    '注册-请填写密码': '請填寫密碼',
    '注册-密码由8~18位字符、数字、特殊符号组成': '密碼由8~18位字符、數字、特殊符號組成',
    '注册-电子邮箱': '電子郵箱',
    '注册-请填写电子邮箱': '請填寫電子郵箱',
    '注册-请输入正确的电子邮箱': '請輸入正確的電子郵箱',
    '注册-邮箱验证码': '郵箱驗證碼',
    '注册-请填写邮箱验证码': '請填寫郵箱驗證碼',
    '注册-发送验证码': '發送驗證碼',
    '注册-验证码发送成功': '發送成功！',
    '注册-姓名': '姓名',
    '注册-请填写姓名': '請填寫姓名',
    '注册-邀请码': '邀請碼（可選填）',
    '注册-注册': '註冊',
    '注册-注册成功，即将跳转登录页面...': '註冊成功，即將跳轉登錄頁面...',
    '注册-我们推荐使用以下浏览器以获得最佳使用体验': '我們推薦使用以下瀏覽器以獲得最佳使用體驗',
    '注册-如果您在使用本网站遇到任何问题，请与我们联系': '如果您在使用本網站遇到任何問題，請與我們聯繫',

    // 忘记密码
    '忘记密码-忘记密码': '忘記密碼',
    '忘记密码-您的密码必须根据以下规则:': '您的密碼必須根據以下規則:',
    '忘记密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.密碼由8~18位字符、數字、特殊符號組成。',
    '忘记密码-电子邮箱': '電子郵箱',
    '忘记密码-请填写电子邮箱': '請填寫電子郵箱',
    '忘记密码-请输入正确的电子邮箱': '請輸入正確的電子郵箱',
    '忘记密码-邮箱验证码': '郵箱驗證碼',
    '忘记密码-发送验证码': '發送驗證碼',
    '忘记密码-请填写邮箱验证码': '請填寫郵箱驗證碼',
    '忘记密码-验证码发送成功': '發送成功',
    '忘记密码-新密码': '新密碼',
    '忘记密码-请填写新密码': '請填寫新密碼',
    '忘记密码-密码由8~18位字符、数字、特殊符号组成': '密碼由8~18位字符、數字、特殊符號組成',
    '忘记密码-确认新密码': '確認新密碼',
    '忘记密码-请填写确认新密码': '請填寫確認新密碼',
    '忘记密码-与新密码不一致': '與新密碼不一致',
    '忘记密码-取消': '取消',
    '忘记密码-提交': '提交',
    '忘记密码-修改成功': '修改成功',

    // 顶部菜单
    '顶部菜单-今日': '今日',
    '顶部菜单-早盘': '早盤',
    '顶部菜单-团队': '團隊',
    '顶部菜单-盈利报表': '盈利報表',
    '顶部菜单-锦标赛': '錦標賽',
    '顶部菜单-余额': '餘額',
    '顶部菜单-提现': '提現',
    '顶部菜单-邀请码': '邀請碼',
    '顶部菜单-数字钱包': '數字錢包',
    '顶部菜单-子-钱包充值': '錢包充值',
    '顶部菜单-子-钱包提现': '錢包提現',
    '顶部菜单-子-钱包记录': '錢包記錄',
    '顶部菜单-子-兑换': '兌換',
    '顶部菜单-子-UU消费记录': 'UU 消費記錄',
    '顶部菜单-子-TT消费记录': 'TT 消費記錄',
    '顶部菜单-修改密码': '修改密碼',
    '顶部菜单-修改邮箱绑定': '修改郵箱綁定',
    '顶部菜单-公告': '公告',
    '顶部菜单-联系我们': '聯繫我們',
    '顶部菜单-玩法规则': '玩法規則',
    '顶部菜单-语言切换': '語言切換',
    '顶部菜单-退出': '退出',

    // 底部菜单
    '底部菜单-我的赛事': '我的賽事',
    '底部菜单-赛程': '賽程',
    '底部菜单-注单': '註單',
    '底部菜单-坐庄记录': '坐莊記錄',
    '底部菜单-投注记录': '投註記錄',

    // 邀请
    '邀请-邀请好友立即获得高额收益返佣': '邀請好友立即獲得高額收益返傭',
    '邀请-邀请码': '邀請碼',
    '邀请-复制链接': '複製鏈接',
    '邀请-复制成功': '複製成功',

    // 钱包 - 钱包充值
    '钱包-钱包充值-钱包充值': '錢包充值',
    '钱包-钱包充值-您的存款地址': '您的存款地址：',
    '钱包-钱包充值-复制': '複製',
    '钱包-钱包充值-复制成功': '複製成功！',
    '钱包-钱包充值-最低存款100USDT，较低的金额可能不会被记入，或被退款。': '最低存款100USDT，較低的金額可能不會被記入，或被退款。',

    // 钱包 - 钱包提现
    '钱包-钱包提现-提现': '錢包提現',
    '钱包-钱包提现-选择币种': '選擇幣種',
    '钱包-钱包提现-选择网络': '選擇網絡',
    '钱包-钱包提现-您的提款地址': '您的提款地址：',
    '钱包-钱包提现-请输入提款地址': '請輸入提款地址：',
    '钱包-钱包提现-数量': '數量',
    '钱包-钱包提现-请输入数量': '請輸入數量',
    '钱包-钱包提现-余额': '餘額',
    '钱包-钱包提现-最低存款100USDT，较低的金额可能不会被记入，或被退款。': '最低存款100USDT，較低的金額可能不會被記入，或被退款。',
    '钱包-钱包提现-确认': '確認',
    '钱包-钱包提现-网络交易需要0-2小时，且网络确认不包括在内': '網絡交易需要0-2小時，且網絡確認不包括在內',
    '钱包-钱包提现-提交成功': '提交成功！',

    // 钱包 - 钱包记录
    '钱包-钱包记录-钱包记录': '錢包記錄',
    '钱包-钱包记录-选择类型': '選擇類型',
    '钱包-钱包记录-全部': '全部',
    '钱包-钱包记录-充值': '充值',
    '钱包-钱包记录-提现': '提現',
    '钱包-钱包记录-兑换': '兌換',
    '钱包-钱包记录-选择开始日期': '選擇開始日期',
    '钱包-钱包记录-选择结束日期': '選擇結束日期',
    '钱包-钱包记录-币种': '幣種',
    '钱包-钱包记录-网络': '網絡',
    '钱包-钱包记录-类型': '類型',
    '钱包-钱包记录-金额': '金額',
    '钱包-钱包记录-手续费': '手續費',
    '钱包-钱包记录-状态': '狀態',
    '钱包-钱包记录-确认中': '確認中',
    '钱包-钱包记录-已确认': '已確認',
    '钱包-钱包记录-充值地址': '充值地址：',
    '钱包-钱包记录-充值时间': '充值時間：',
    '钱包-钱包记录-提现地址': '提現地址：',
    '钱包-钱包记录-到账时间': '到賬時間：',
    '钱包-钱包记录-描述': '描述：',
    '钱包-钱包记录-时间': '時間：',

    // 钱包 - 兑换
    '钱包-兑换-兑换': '兌換',
    '钱包-兑换-USDT兑换UU': 'USDT兌換UU',
    '钱包-兑换-UU兑换USDT': 'UU兌換USDT',
    '钱包-兑换-USDT兑换TT': 'USDT兌換TT',
    '钱包-兑换-TT兑换USDT': 'TT兌換USDT',
    '钱包-兑换-请输入数量': '請輸入數量！',
    '钱包-兑换-余额': '餘額：',
    '钱包-兑换-提交成功': '提交成功',

    // 钱包 - UU消费记录
    '钱包-UU消费记录-消费记录': 'UU 消費記錄',
    '钱包-UU消费记录-选择类型': '選擇類型',
    '钱包-UU消费记录-选择开始日期': '選擇開始日期',
    '钱包-UU消费记录-选择结束日期': '選擇結束日期',
    '钱包-UU消费记录-时间': '時間',
    '钱包-UU消费记录-类型': '類型',
    '钱包-UU消费记录-金额': '金額',
    '钱包-UU消费记录-余额': '餘額',
    '钱包-UU消费记录-描述': '描述：',
    '钱包-UU消费记录-订单': '訂單：',

    // 钱包 - TT消费记录
    '钱包-TT消费记录-消费记录': 'TT 消費記錄',
    '钱包-TT消费记录-选择类型': '選擇類型',
    '钱包-TT消费记录-选择开始日期': '選擇開始日期',
    '钱包-TT消费记录-选择结束日期': '選擇結束日期',
    '钱包-TT消费记录-时间': '時間',
    '钱包-TT消费记录-类型': '類型',
    '钱包-TT消费记录-金额': '金額',
    '钱包-TT消费记录-余额': '餘額',
    '钱包-TT消费记录-描述': '描述：',
    '钱包-TT消费记录-订单': '訂單：',

    // 修改密码
    '修改密码-修改密码': '修改密碼',
    '修改密码-您的密码必须根据以下规则:': '您的密碼必須根據以下規則:',
    '修改密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.密碼由8~18位字符、數字、特殊符號組成。',
    '修改密码-旧密码': '舊密碼',
    '修改密码-请填写旧密码': '請填寫舊密碼',
    '修改密码-新密码': '新密碼',
    '修改密码-请填写新密码': '請填寫新密碼',
    '修改密码-密码由8~18位字符、数字、特殊符号组成': '密碼由8~18位字符、數字、特殊符號組成',
    '修改密码-取消': '取消',
    '修改密码-提交': '提交',
    '修改密码-修改成功': '修改成功！',

    // 修改绑定邮箱
    '修改绑定邮箱-修改绑定邮箱': '修改綁定郵箱',
    '修改绑定邮箱-您的邮箱必须根据以下规则:': '您的郵箱必須根據以下規則:',
    '修改绑定邮箱-1.验证码有效期 10min。': '1.驗證碼有效期 10min。',
    '修改绑定邮箱-2.每个验证码只能验证一次。': '2.每個驗證碼只能驗證一次。',
    '修改绑定邮箱-旧邮箱': '舊郵箱',
    '修改绑定邮箱-新的邮箱': '新的郵箱',
    '修改绑定邮箱-请填写新的邮箱': '請填寫新的郵箱',
    '修改绑定邮箱-请输入正确的电子邮箱': '請輸入正確的電子郵箱',
    '修改绑定邮箱-邮箱验证码': '郵箱驗證碼',
    '修改绑定邮箱-请填写邮箱验证码': '請填寫郵箱驗證碼',
    '修改绑定邮箱-发送验证码': '發送驗證碼',
    '修改绑定邮箱-验证码发送成功': '驗證碼發送成功',
    '修改绑定邮箱-取消': '取消',
    '修改绑定邮箱-提交': '提交',
    '修改绑定邮箱-修改成功': '修改成功！',

    // 公告
    '公告-公告': '公告',
    '公告-一般': '一般',
    '公告-重要': '重要',
    '公告-个人': '個人',
    '公告-全部': '全部',
    '公告-今日': '今日',
    '公告-昨日': '昨日',

    // 联系我们
    '联系我们-联系我们': '聯系我們',
    '联系我们-联系邮箱': '聯系郵箱',

    // 玩法规则
    '玩法规则-玩法规则': '玩法規則',

    // 首页
    '首页-今日赛事': '今日賽事',
    '首页-早盘赛事': '早盤賽事',

    // 今日
    '今日-热门赛事': '熱門賽事',

    // 早盘
    '早盘-热门赛事': '熱門賽事',
    '早盘-所有日期': '所有日期',
    '早盘-星期一': '星期一',
    '早盘-星期二': '星期二',
    '早盘-星期三': '星期三',
    '早盘-星期四': '星期四',
    '早盘-星期五': '星期五',
    '早盘-星期六': '星期六',
    '早盘-星期日': '星期日',

    // 团队
    '团队-返佣总额': '返傭總額',
    '团队-团队人数': '團隊人數',
    '团队-人': '人',
    '团队-一级': '一級',
    '团队-二级': '二級',
    '团队-三级': '三級',
    '团队-选择开始日期': '選擇開始日期',
    '团队-选择结束日期': '選擇結束日期',
    '团队-会员名称': '會員名稱',
    '团队-推广返佣': '推廣返傭',
    '团队-推广分成': '推廣分成',
    '团队-详情': '詳情',
    // 团队详情
    '团队详情 - 团队详情': '團隊詳情',
    '团队详情-选择开始日期': '選擇開始日期',
    '团队详情-选择结束日期': '選擇結束日期',
    '团队详情-会员ID': '會員ID',
    '团队详情-类型': '類型',
    '团队详情-推广分成': '推廣分成',
    '团队详情-推广返佣': '推廣返傭',

    // 盈利报表
    '盈利报表-选择': '選擇',
    '盈利报表-选择-普通': '普通盈利報表',
    '盈利报表-选择-比赛': '比賽盈利報表',
    '盈利报表-坐庄报表': '坐莊報表',
    '盈利报表-投注报表': '投註報表',
    '盈利报表-合计报表': '合計報表',
    // 盈利报表 - 坐庄报表
    '盈利报表-坐庄报表-选择周期': '選擇週期',
    '盈利报表-坐庄报表-选择开始日期': '選擇開始日期',
    '盈利报表-坐庄报表-选择结束日期': '選擇結束日期',
    '盈利报表-坐庄报表-时间': '時間',
    '盈利报表-坐庄报表-坐庄ID': '坐莊ID',
    '盈利报表-坐庄报表-投注金额': '投註金額',
    '盈利报表-坐庄报表-有效金额': '有效金額',
    '盈利报表-坐庄报表-手续费': '手續費',
    '盈利报表-坐庄报表-输赢': '輸贏',
    '盈利报表-坐庄报表-笔数': '筆數',
    '盈利报表-坐庄报表-总计': '總計',
    // 盈利报表 - 坐庄报表 - 投注记录
    '盈利报表-坐庄报表-投注记录-坐庄投注记录': '坐莊投註記錄',
    '盈利报表-坐庄报表-投注记录-选择开始日期': '選擇開始日期',
    '盈利报表-坐庄报表-投注记录-选择结束日期': '選擇結束日期',
    '盈利报表-坐庄报表-投注记录-足球': '足球',
    '盈利报表-坐庄报表-投注记录-下注金额': '下註金額：',
    '盈利报表-坐庄报表-投注记录-结果': '結果：',
    '盈利报表-坐庄报表-投注记录-总计': '總計',
    '盈利报表-坐庄报表-投注记录-下注总计': '下註總計：',
    '盈利报表-坐庄报表-投注记录-结果总计': '結果總計：',
    '盈利报表-坐庄报表-投注记录-返回顶部': '返回頂部',
    // 盈利报表 - 坐庄报表 - 详情
    '盈利报表-坐庄报表-详情-坐庄报表': '坐莊報表',
    '盈利报表-坐庄报表-详情-选择开始日期': '選擇開始日期',
    '盈利报表-坐庄报表-详情-选择结束日期': '選擇結束日期',
    '盈利报表-坐庄报表-详情-保证金额': '保證金額',
    '盈利报表-坐庄报表-详情-投注金额': '投註金額',
    '盈利报表-坐庄报表-详情-输赢': '輸贏',
    '盈利报表-坐庄报表-详情-保证金': '保證金',
    '盈利报表-坐庄报表-详情-门票价': '門票價',
    '盈利报表-坐庄报表-详情-总投注金额': '總投注金额',
    '盈利报表-坐庄报表-详情-笔': '筆',
    '盈利报表-坐庄报表-详情-手续费': '手續費',
    '盈利报表-坐庄报表-详情-玩家输赢': '輸贏',
    '盈利报表-坐庄报表-详情-奖池手续费': '獎池手續費',
    '盈利报表-坐庄报表-详情-奖池余额': '獎池餘額',
    '盈利报表-坐庄报表-详情-奖池输赢': '獎池輸贏',

    // 盈利报表 - 投注报表
    '盈利报表-投注报表-选择周期': '選擇週期',
    '盈利报表-投注报表-今日': '今日',
    '盈利报表-投注报表-昨日': '昨日',
    '盈利报表-投注报表-一周内': '一周內',
    '盈利报表-投注报表-一月内': '一月內',
    '盈利报表-投注报表-选择开始日期': '選擇開始日期',
    '盈利报表-投注报表-选择结束日期': '選擇結束日期',
    '盈利报表-投注报表-投注金额': '投註金額',
    '盈利报表-投注报表-有效金额': '有效金額',
    '盈利报表-投注报表-输赢': '輸贏',
    '盈利报表-投注报表-日期': '日期',
    // 盈利报表 - 投注报表 - 详情
    '盈利报表-投注报表-详情-选择时间': '選擇時間',
    '盈利报表-投注报表-详情-足球': '足球',
    '盈利报表-投注报表-详情-下注金额': '下註金額：',
    '盈利报表-投注报表-详情-结果': '結果：',
    '盈利报表-投注报表-详情-总计': '總計',
    '盈利报表-投注报表-详情-下注总计': '下註總計：',
    '盈利报表-投注报表-详情-结果总计': '結果總計：',
    '盈利报表-投注报表-详情-返回顶部': '返回頂部',

    // 盈利报表 - 合计报表
    '盈利报表-合计报表-开始日期': '開始日期',
    '盈利报表-合计报表-结束日期': '結束日期',
    '盈利报表-合计报表-日期': '日期',
    '盈利报表-合计报表-投注赢输': '投註輸贏',
    '盈利报表-合计报表-坐庄赢输': '坐莊輸贏',
    '盈利报表-合计报表-总计': '總計',

    // 我的赛事
    '我的赛事-我的赛事': '我的賽事',
    '我的赛事-投注': '投註',
    '我的赛事-坐庄': '坐莊',
    '我的赛事-取消收藏': '取消收藏',
    '我的赛事-确认是否取消收藏当前比赛': '確認是否取消收藏當前比賽？',
    '我的赛事-已取消收藏': '已取消收藏！',

    // 赛程
    '赛程-赛程': '賽程',
    '赛程-所有日期': '所有日期',
    '赛程-星期一': '星期一',
    '赛程-星期二': '星期二',
    '赛程-星期三': '星期三',
    '赛程-星期四': '星期四',
    '赛程-星期五': '星期五',
    '赛程-星期六': '星期六',
    '赛程-星期日': '星期日',
    '赛程-投注': '投註',
    '赛程-坐庄': '坐莊',
    '赛程-收藏': '收藏',
    '赛程-取消收藏': '取消收藏',
    '赛程-确认是否收藏当前比赛': '確認是否收藏當前比賽？',
    '赛程-收藏成功': '收藏成功！',
    '赛程-确认是否取消收藏当前比赛': '確認是否取消收藏當前比賽？',
    '赛程-已取消收藏': '已取消收藏！',

    // 注单
    '注单-注单': '注單',
    '注单-足球': '足球',
    '注单-请输入下注金额': '請輸入下註金額',
    '注单-最大投注金额': '最大投註金額',
    '注单-最小投注金额': '最小投註金額',
    '注单-下注总额': '下註總額',
    '注单-预估可赢': '預估可贏',
    '注单-下注': '下註',
    '注单-下注中': '下註中...',
    '注单-下注成功': '下註成功',
    '注单-下注失败': '下註失敗',
    '注单-确认': '確認',

    // 坐庄记录
    '坐庄记录-坐庄记录': '坐莊記錄',
    '坐庄记录-普通': '普通',
    '坐庄记录-比赛': '比賽',
    '坐庄记录-奖池总额': '獎池總額：',
    '坐庄记录-下注总额': '下註總額：',
    '坐庄记录-笔': '筆',
    '坐庄记录-撤销': '撤銷',
    '坐庄记录-确认是否撤销': '確認是否撤銷？',
    '坐庄记录-已撤销': '已撤銷',
    '坐庄记录-保证金': '保證金',
    '坐庄记录-最低投注': '最低投註',
    '坐庄记录-总投注金额': '總投註金額',
    '坐庄记录-胜负平': '勝負平',
    '坐庄记录-主': '主',
    '坐庄记录-和': '和',
    '坐庄记录-客': '客',
    '坐庄记录-波胆': '波膽',
    '坐庄记录-让球': '讓球',
    '坐庄记录-大小球': '大小球',
    '坐庄记录-大': '大',
    '坐庄记录-小': '小',

    // 投注记录
    '投注记录-投注记录': '投注記錄',
    '投注记录-普通': '普通',
    '投注记录-比赛': '比賽',
    '投注记录-笔': '筆',
    '投注记录-投注金额': '投註金額：',
    '投注记录-结果': '結果：',
    '投注记录-足球': '足球',

    // 比赛列表
    '比赛列表-比赛列表': '比賽列表',
    '比赛列表-投注': '投註',
    '比赛列表-坐庄': '坐莊',
    '比赛列表-收藏': '收藏',
    '比赛列表-取消收藏': '取消收藏',
    '比赛列表-确认是否收藏当前比赛': '確認是否收藏當前比賽？',
    '比赛列表-收藏成功': '收藏成功！',
    '比赛列表-确认是否取消收藏当前比赛': '確認是否取消收藏當前比賽？',
    '比赛列表-已取消收藏': '已取消收藏！',

    // 坐庄
    '坐庄-保证金额': '保證金額',
    '坐庄-最低投注': '最低投註',
    '坐庄-创建': '創建',
    '坐庄-编辑': '編輯',
    '坐庄-删除': '刪除',
    '坐庄-平局': '平局',
    '坐庄-和局': '和局',
    '坐庄-其他': '其他',
    '坐庄-胜负平': '勝負平',
    '坐庄-波胆': '波膽',
    '坐庄-让球': '讓球',
    '坐庄-大小球': '大小球',
    '坐庄-确认': '確認',
    '坐庄-请输入': '請輸入',
    '坐庄-设置赔率': '設置賠率',
    '坐庄-请输入设置赔率': '請輸入設置賠率！',
    '坐庄-胜': '勝',
    '坐庄-让': '讓',
    '坐庄-大': '大',
    '坐庄-小': '小',
    '坐庄-提交成功': '提交成功！',

    // 投注列表
    '投注列表-投注列表': '投註列表',
    '投注列表-胜负平': '勝負平',
    '投注列表-主': '主',
    '投注列表-和': '和',
    '投注列表-客': '客',
    '投注列表-波胆': '波膽',
    '投注列表-让球': '讓球',
    '投注列表-大小球': '大小球',
    '投注列表-大': '大',
    '投注列表-小': '小',
    '投注列表-现货量': '現貨量：',
    '投注列表-最低投注': '最低投註：',
    '投注列表-投注': '投註',
    '投注列表-足球': '足球',
    '投注列表-请输入下注金额': '請輸入下註金額',
    '投注列表-下注金额': '下註金額',
    '投注列表-设置金额': '設置金額',
    '投注列表-可赢金额': '可贏金額',
    '投注列表-加到下注单': '加到下註單',
    '投注列表-下注': '下註',
    '投注列表-您已投注成功': '您已投註成功！',
    '投注列表-确认': '確認',
    '投注列表-提交成功': '提交成功',

    // 比赛
    '比赛-锦标赛列表': '錦標賽列表',
    '比赛-奖池金额': '獎池金額',
    '比赛-收益': '收益',
    '比赛-排名': '排名',
    '比赛-所有日期': '所有日期',
    '比赛-星期一': '星期一',
    '比赛-星期二': '星期二',
    '比赛-星期三': '星期三',
    '比赛-星期四': '星期四',
    '比赛-星期五': '星期五',
    '比赛-星期六': '星期六',
    '比赛-星期日': '星期日',
    '比赛-投注': '投註',
    '比赛-坐庄': '坐莊',

    // 比赛 - 排名
    '比赛排名-排名': '排名',

    // 比赛 - 坐庄
    '比赛坐庄-保证金额': '保證金額',
    '比赛坐庄-最低投注': '最低投註',
    '比赛坐庄-创建': '創建',
    '比赛坐庄-编辑': '編輯',
    '比赛坐庄-删除': '刪除',
    '比赛坐庄-平局': '平局',
    '比赛坐庄-和局': '和局',
    '比赛坐庄-胜负平': '勝負平',
    '比赛坐庄-确认': '確認',
    '比赛坐庄-请输入': '請輸入',
    '比赛坐庄-设置赔率': '設置賠率',
    '比赛坐庄-请输入设置赔率': '請輸入設置賠率！',
    '比赛坐庄-胜': '勝',
    '比赛坐庄-提交成功': '提交成功！',

    // 比赛 - 投注
    '比赛投注-投注列表': '投註列表',
    '比赛投注-胜负平': '勝負平',
    '比赛投注-主': '主',
    '比赛投注-和': '和',
    '比赛投注-客': '客',
    '比赛投注-现货量': '現貨量：',
    '比赛投注-最低投注': '最低投註：',
    '比赛投注-投注': '投註',
    '比赛投注-足球': '足球',
    '比赛投注-请输入下注金额': '請輸入下註金額',
    '比赛投注-下注金额': '下註金額',
    '比赛投注-设置金额': '設置金額',
    '比赛投注-可赢金额': '可贏金額',
    '比赛投注-下注': '下註',
    '比赛投注-您已投注成功': '您已投註成功！',
    '比赛投注-确认': '確認',
    '比赛投注-提交成功': '提交成功',
}
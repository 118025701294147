export default [
    {
        path: "/home",
        name: "home",
        meta: {
            title: '首页',
        },
        component: () => import("@/views/home/index")
    },
    {
        path: "/invite",
        name: "invite",
        meta: {
            title: '邀请',
        },
        component: () => import("@/views/home/invite")
    },
    {
        path: "/wallet/recharge",
        name: "recharge",
        meta: {
            title: '钱包-钱包充值',
        },
        component: () => import("@/views/wallet/recharge")
    },
    {
        path: "/wallet/withdraw",
        name: "withdraw",
        meta: {
            title: '钱包-钱包提现',
        },
        component: () => import("@/views/wallet/withdraw")
    },
    {
        path: "/wallet/record",
        name: "record",
        meta: {
            title: '钱包-钱包记录',
        },
        component: () => import("@/views/wallet/record")
    },
    {
        path: "/wallet/exchange",
        name: "exchange",
        meta: {
            title: '钱包-兑换',
        },
        component: () => import("@/views/wallet/exchange")
    },
    {
        path: "/wallet/consumptionRecordUU",
        name: "consumptionRecordUU",
        meta: {
            title: '钱包-UU消费记录',
        },
        component: () => import("@/views/wallet/consumptionRecordUU")
    },
    {
        path: "/wallet/consumptionRecordTT",
        name: "consumptionRecordTT",
        meta: {
            title: '钱包-TT消费记录',
        },
        component: () => import("@/views/wallet/consumptionRecordTT")
    },
    {
        path: "/changePassword",
        name: "changePassword",
        meta: {
            title: '修改密码',
        },
        component: () => import("@/views/home/changePassword")
    },
    {
        path: "/changeEmail",
        name: "changeEmail",
        meta: {
            title: '修改绑定邮箱',
        },
        component: () => import("@/views/home/changeEmail")
    },
    {
        path: "/notice",
        name: "notice",
        meta: {
            title: '公告',
        },
        component: () => import("@/views/notice/index")
    },
    {
        path: "/contact",
        name: "contact",
        meta: {
            title: '联系我们',
        },
        component: () => import("@/views/home/contact")
    },
    {
        path: "/rule",
        name: "rule",
        meta: {
            title: '玩法规则',
        },
        component: () => import("@/views/home/rule")
    },
    {
        path: "/ruleDetails",
        name: "ruleDetails",
        meta: {
            title: '玩法规则-详情',
        },
        component: () => import("@/views/home/ruleDetails")
    },
    {
        path: "/today",
        name: "today",
        meta: {
            title: '今日',
        },
        component: () => import("@/views/today/index")
    },
    {
        path: "/tomorrow",
        name: "tomorrow",
        meta: {
            title: '早盘',
        },
        component: () => import("@/views/tomorrow/index")
    },
    {
        path: "/profitReport",
        name: "profitReport",
        meta: {
            title: '盈利报表',
        },
        component: () => import("@/views/profitReport/index")
    },
    {
        path: "/ranking",
        name: "ranking",
        meta: {
            title: '比赛排名',
        },
        component: () => import("@/views/jackpot/ranking")
    },
    {
        path: "/bankerDetails",
        name: "bankerDetails",
        meta: {
            title: '坐庄报表详情',
        },
        component: () => import("@/views/profitReport/bankerDetails")
    },
    {
        path: "/bankerBet",
        name: "bankerBet",
        meta: {
            title: '坐庄报表下投注记录',
        },
        component: () => import("@/views/profitReport/bankerBet")
    },
    {
        path: "/bettingDetails",
        name: "bettingDetails",
        meta: {
            title: '投注报表详情',
        },
        component: () => import("@/views/profitReport/bettingDetails")
    },
    {
        path: "/myCompetition",
        name: "myCompetition",
        meta: {
            title: '我的赛事',
        },
        component: () => import("@/views/myCompetition/index")
    },
    {
        path: "/competition",
        name: "competition",
        meta: {
            title: '赛程',
        },
        component: () => import("@/views/competition/index")
    },
    {
        path: "/bankerRecord",
        name: "bankerRecord",
        meta: {
            title: '坐庄记录',
        },
        component: () => import("@/views/bankerRecord/index")
    },
    {
        path: "/bettingRecord",
        name: "bettingRecord",
        meta: {
            title: '投注记录',
        },
        component: () => import("@/views/bettingRecord/index")
    },
    {
        path: "/competitionList",
        name: "competitionList",
        meta: {
            title: '比赛列表',
        },
        component: () => import("@/views/competitionList/index")
    },
    {
        path: "/banker",
        name: "banker",
        meta: {
            title: '坐庄',
        },
        component: () => import("@/views/banker/index")
    },
    {
        path: "/betting",
        name: "betting",
        meta: {
            title: '投注',
        },
        component: () => import("@/views/betting/index")
    },

    {
        path: "/jackpot",
        name: "jackpot",
        meta: {
            title: '比赛',
        },
        component: () => import("@/views/jackpot/index")
    },
    {
        path: "/jackpotBanker",
        name: "jackpotBanker",
        meta: {
            title: '比赛坐庄',
        },
        component: () => import("@/views/jackpot/banker")
    },
    {
        path: "/jackpotBetting",
        name: "jackpotBetting",
        meta: {
            title: '比赛投注',
        },
        component: () => import("@/views/jackpot/betting")
    },
    {
        path: "/rebate",
        name: "rebate",
        meta: {
            title: '团队',
        },
        component: () => import("@/views/rebate/index")
    },
    {
        path: "/rebateDetails",
        name: "rebateDetails",
        meta: {
            title: '团队详情',
        },
        component: () => import("@/views/rebate/rebateDetails")
    }
]
export default {
    '暂无数据': 'No data available',
    '没有更多了': 'No more',
    'Dialog确认': 'Confirm',
    'Dialog取消': 'Cancel',
    '语言切换-简体版': '简体版',
    '语言切换-繁體版': '繁體版',
    '语言切换-ENGLISH': 'English',
    '语言切换-JAPANESE': 'Japanese',
    '其它': 'Other',
    '锦标赛奖池': 'Championship prize pool',

    // 登录
    '登录-电子邮箱/用户名': 'Email/Username',
    '登录-请填写电子邮箱/用户名': 'Please fill in your email/username',
    '登录-密码': 'Password',
    '登录-请填写密码': 'Please fill in the password',
    '登录-登入': 'Log in',
    '登录-去注册': 'Register',
    '登录-忘记密码': 'Forgot password？',
    '登录-提示-登录中': 'Logging...',
    '登录-我们推荐使用以下浏览器以获得最佳使用体验': 'We recommend the following browsers for the best experience',
    '登录-如果您在使用本网站遇到任何问题，请与我们联系': 'If you have any problems using this website, please contact us',

    // 注册
    '注册-登入': 'Log in',
    '注册-用户名': 'Username',
    '注册-请填写用户名': 'Please fill in the username',
    '注册-用户名格式错误': 'Usernames consist of 6 to 16 letters or numbers',
    '注册-密码': 'Password',
    '注册-请填写密码': 'Please fill in the password',
    '注册-密码由8~18位字符、数字、特殊符号组成': 'The password consists of 8 to 18 characters, digits, and special symbols',
    '注册-电子邮箱': 'Email address',
    '注册-请填写电子邮箱': 'Please fill in your email address',
    '注册-请输入正确的电子邮箱': 'Please enter the correct email address',
    '注册-邮箱验证码': 'Email verification code',
    '注册-请填写邮箱验证码': 'Please fill in the email verification code',
    '注册-发送验证码': 'Send',
    '注册-验证码发送成功': 'Sent successfully！',
    '注册-姓名': 'name',
    '注册-请填写姓名': 'Please fill in your name',
    '注册-邀请码': 'Invitation code（ Optional ）',
    '注册-注册': 'Sign up',
    '注册-注册成功，即将跳转登录页面...': 'Registration is successful, you will be redirected to the login page...',
    '注册-我们推荐使用以下浏览器以获得最佳使用体验': 'We recommend the following browsers for the best experience',
    '注册-如果您在使用本网站遇到任何问题，请与我们联系': 'If you have any problems using this website, please contact us',

    // 忘记密码
    '忘记密码-忘记密码': 'Forgot password',
    '忘记密码-您的密码必须根据以下规则:': 'Your password must be based on the following rules:',
    '忘记密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.The password consists of 8 to 18 characters, digits, and special symbols.',
    '忘记密码-电子邮箱': 'Email address',
    '忘记密码-请填写电子邮箱': 'Please fill in your email address',
    '忘记密码-请输入正确的电子邮箱': 'Please enter the correct email address',
    '忘记密码-邮箱验证码': 'Email verification code',
    '忘记密码-发送验证码': 'Send',
    '忘记密码-请填写邮箱验证码': 'Please fill in the email verification code',
    '忘记密码-验证码发送成功': 'Sent successfully！',
    '忘记密码-新密码': 'New password',
    '忘记密码-请填写新密码': 'Please fill in the new password',
    '忘记密码-密码由8~18位字符、数字、特殊符号组成': 'The password consists of 8 to 18 characters, digits, and special symbols',
    '忘记密码-确认新密码': 'Confirm new password',
    '忘记密码-请填写确认新密码': 'Please confirm the new password',
    '忘记密码-与新密码不一致': 'Inconsistent with the new password',
    '忘记密码-取消': 'Cancel',
    '忘记密码-提交': 'Submit',
    '忘记密码-修改成功': 'Changed successfully',

    // 顶部菜单
    '顶部菜单-今日': 'Today',
    '顶部菜单-早盘': 'Early',
    '顶部菜单-团队': 'Team',
    '顶部菜单-盈利报表': 'Profit',
    '顶部菜单-锦标赛': 'Championships',
    '顶部菜单-余额': 'Balance',
    '顶部菜单-提现': 'Withdraw',
    '顶部菜单-邀请码': 'Invitation code',
    '顶部菜单-数字钱包': 'Digital wallet',
    '顶部菜单-子-钱包充值': 'Wallet recharge',
    '顶部菜单-子-钱包提现': 'Wallet withdrawal',
    '顶部菜单-子-钱包记录': 'Wallet record',
    '顶部菜单-子-兑换': 'Exchange',
    '顶部菜单-子-UU消费记录': 'UU transaction record',
    '顶部菜单-子-TT消费记录': 'TT transaction record',
    '顶部菜单-修改密码': 'Change password',
    '顶部菜单-修改邮箱绑定': 'Change Email Binding',
    '顶部菜单-公告': 'Announcement',
    '顶部菜单-联系我们': 'Contact us',
    '顶部菜单-玩法规则': 'Gameplay rules',
    '顶部菜单-语言切换': 'Language switching',
    '顶部菜单-退出': 'Quit',

    // 底部菜单
    '底部菜单-我的赛事': 'My event',
    '底部菜单-赛程': 'Schedule',
    '底部菜单-注单': 'Bet',
    '底部菜单-坐庄记录': 'Banker record',
    '底部菜单-投注记录': 'Betting record',

    // 邀请
    '邀请-邀请好友立即获得高额收益返佣': 'Invite a friend and get a high revenue rebate immediately',
    '邀请-邀请码': 'Invitation code',
    '邀请-复制链接': 'Copy link',
    '邀请-复制成功': 'Successfully copied',

    // 钱包 - 钱包充值
    '钱包-钱包充值-钱包充值': 'Wallet recharge',
    '钱包-钱包充值-您的存款地址': 'Your deposit address：',
    '钱包-钱包充值-复制': 'Copy',
    '钱包-钱包充值-复制成功': 'Successfully copied！',
    '钱包-钱包充值-最低存款100USDT，较低的金额可能不会被记入，或被退款。': 'Minimum deposit 100USDT, lower amounts may not be credited, or refunded.',

    // 钱包 - 钱包提现
    '钱包-钱包提现-提现': 'Wallet withdrawal',
    '钱包-钱包提现-选择币种': 'Select a currency',
    '钱包-钱包提现-选择网络': 'Select network',
    '钱包-钱包提现-您的提款地址': 'Your withdrawal address：',
    '钱包-钱包提现-请输入提款地址': 'Please enter the withdrawal address：',
    '钱包-钱包提现-数量': 'Amount',
    '钱包-钱包提现-请输入数量': 'Please enter Amount',
    '钱包-钱包提现-余额': 'Balance',
    '钱包-钱包提现-最低存款100USDT，较低的金额可能不会被记入，或被退款。': 'Minimum deposit 100USDT, lower amounts may not be credited, or refunded.',
    '钱包-钱包提现-确认': 'Confirm',
    '钱包-钱包提现-网络交易需要0-2小时，且网络确认不包括在内': 'Network transactions take 0-2 hours and network confirmations are not included',
    '钱包-钱包提现-提交成功': 'Submit successfully！',

    // 钱包 - 钱包记录
    '钱包-钱包记录-钱包记录': 'Wallet record',
    '钱包-钱包记录-选择类型': 'Select type',
    '钱包-钱包记录-全部': 'All',
    '钱包-钱包记录-充值': 'Top-up',
    '钱包-钱包记录-提现': 'Withdraw',
    '钱包-钱包记录-兑换': 'Exchange',
    '钱包-钱包记录-选择开始日期': 'Select start date',
    '钱包-钱包记录-选择结束日期': 'Select end date',
    '钱包-钱包记录-币种': 'Currency',
    '钱包-钱包记录-网络': 'Network',
    '钱包-钱包记录-类型': 'Type',
    '钱包-钱包记录-金额': 'Amount',
    '钱包-钱包记录-手续费': 'Admin charge',
    '钱包-钱包记录-状态': 'Status',
    '钱包-钱包记录-确认中': 'Confirming',
    '钱包-钱包记录-已确认': 'Confirmed',
    '钱包-钱包记录-充值地址': 'Recharge address：',
    '钱包-钱包记录-充值时间': 'Recharge time：',
    '钱包-钱包记录-提现地址': 'Withdrawal address：',
    '钱包-钱包记录-到账时间': 'Received time：',
    '钱包-钱包记录-描述': 'Description：',
    '钱包-钱包记录-时间': 'Time：',

    // 钱包 - 兑换
    '钱包-兑换-兑换': 'Exchange',
    '钱包-兑换-USDT兑换UU': 'USDT exchange for UU',
    '钱包-兑换-UU兑换USDT': 'UU exchange for USDT',
    '钱包-兑换-USDT兑换TT': 'USDT exchange for TT',
    '钱包-兑换-TT兑换USDT': 'TT exchange for USDT',
    '钱包-兑换-请输入数量': 'Please enter quantity！',
    '钱包-兑换-余额': 'Balance：',
    '钱包-兑换-提交成功': 'Submit successfully',

    // 钱包 - UU消费记录
    '钱包-UU消费记录-消费记录': 'UU transaction record',
    '钱包-UU消费记录-选择类型': 'Select type',
    '钱包-UU消费记录-选择开始日期': 'Select start date',
    '钱包-UU消费记录-选择结束日期': 'Select end date',
    '钱包-UU消费记录-时间': 'Time',
    '钱包-UU消费记录-类型': 'Type',
    '钱包-UU消费记录-金额': 'Amount',
    '钱包-UU消费记录-余额': 'Balance',
    '钱包-UU消费记录-描述': 'Description：',
    '钱包-UU消费记录-订单': 'Order',

    // 钱包 - TT消费记录
    '钱包-TT消费记录-消费记录': 'TT transaction record',
    '钱包-TT消费记录-选择类型': 'Select type',
    '钱包-TT消费记录-选择开始日期': 'Select start date',
    '钱包-TT消费记录-选择结束日期': 'Select end date',
    '钱包-TT消费记录-时间': 'Time',
    '钱包-TT消费记录-类型': 'Type',
    '钱包-TT消费记录-金额': 'Amount',
    '钱包-TT消费记录-余额': 'Balance',
    '钱包-TT消费记录-描述': 'Description：',
    '钱包-TT消费记录-订单': 'Order',

    // 修改密码
    '修改密码-修改密码': 'Change password',
    '修改密码-您的密码必须根据以下规则:': 'Your password must be based on the following rules:',
    '修改密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.The password consists of 8 to 18 characters, digits, and special symbols.',
    '修改密码-旧密码': 'Old password',
    '修改密码-请填写旧密码': 'Please fill in the old password',
    '修改密码-新密码': 'New password',
    '修改密码-请填写新密码': 'Please fill in the new password',
    '修改密码-密码由8~18位字符、数字、特殊符号组成': 'The password consists of 8 to 18 characters, digits, and special symbols',
    '修改密码-取消': 'Cancel',
    '修改密码-提交': 'Submit',
    '修改密码-修改成功': 'Changed successfully！',

    // 修改绑定邮箱
    '修改绑定邮箱-修改绑定邮箱': 'Changing a Bound Email',
    '修改绑定邮箱-您的邮箱必须根据以下规则:': 'Your email address must follow the following rules:',
    '修改绑定邮箱-1.验证码有效期 10min。': '1.The verification code is valid for 10 minutes.',
    '修改绑定邮箱-2.每个验证码只能验证一次。': '2.Each verification code can only be verified once.',
    '修改绑定邮箱-旧邮箱': 'Old Email address',
    '修改绑定邮箱-新的邮箱': 'New Email address',
    '修改绑定邮箱-请填写新的邮箱': 'Please fill in a new email address',
    '修改绑定邮箱-请输入正确的电子邮箱': 'Please enter the correct email address',
    '修改绑定邮箱-邮箱验证码': 'Email verification code',
    '修改绑定邮箱-请填写邮箱验证码': 'Please fill in the email verification code',
    '修改绑定邮箱-发送验证码': 'Send',
    '修改绑定邮箱-验证码发送成功': 'Sent successfully',
    '修改绑定邮箱-取消': 'Cancel',
    '修改绑定邮箱-提交': 'Submit',
    '修改绑定邮箱-修改成功': 'Changed successfully',

    // 公告
    '公告-公告': 'Announcement',
    '公告-一般': 'Normal',
    '公告-重要': 'Important',
    '公告-个人': 'Personal',
    '公告-全部': 'All',
    '公告-今日': 'Today',
    '公告-昨日': 'Yesterday',

    // 联系我们
    '联系我们-联系我们': 'Contact Us',
    '联系我们-联系邮箱': 'Email',

    // 玩法规则
    '玩法规则-玩法规则': 'Gameplay rules',

    // 首页
    '首页-今日赛事': 'Match of the day',
    '首页-早盘赛事': 'Early match',

    // 今日
    '今日-热门赛事': 'Popular match',

    // 早盘
    '早盘-热门赛事': 'Popular match',
    '早盘-所有日期': 'All dates',
    '早盘-星期一': 'Monday.',
    '早盘-星期二': 'Tuesday.',
    '早盘-星期三': 'Wednesday.',
    '早盘-星期四': 'Thursday.',
    '早盘-星期五': 'Friday.',
    '早盘-星期六': 'Saturday.',
    '早盘-星期日': 'Sunday.',

    // 团队
    '团队-返佣总额': 'Rebate total amount',
    '团队-团队人数': 'Team size',
    '团队-人': 'people',
    '团队-一级': 'one-level',
    '团队-二级': 'two-level',
    '团队-三级': 'three-level',
    '团队-选择开始日期': 'Select start date',
    '团队-选择结束日期': 'Select end date',
    '团队-会员名称': 'Member name',
    '团队-推广返佣': 'Promotion rebate',
    '团队-推广分成': 'Promotion split',
    '团队-详情': 'Details',
    // 团队详情
    '团队详情 - 团队详情': 'Team details',
    '团队详情-选择开始日期': 'Select start date',
    '团队详情-选择结束日期': 'Select end date',
    '团队详情-会员ID': 'Member ID',
    '团队详情-类型': 'Type',
    '团队详情-推广分成': 'Promotion split',
    '团队详情-推广返佣': 'Promotion rebate',

    // 盈利报表
    '盈利报表-选择': 'Select',
    '盈利报表-选择-普通': 'Regular profit report',
    '盈利报表-选择-比赛': 'Competition Profit Report',
    '盈利报表-坐庄报表': 'Banker',
    '盈利报表-投注报表': 'Bet',
    '盈利报表-合计报表': 'All',
    // 盈利报表 - 坐庄报表
    '盈利报表-坐庄报表-选择周期': 'Select cycle',
    '盈利报表-坐庄报表-选择开始日期': 'Select start date',
    '盈利报表-坐庄报表-选择结束日期': 'Select end date',
    '盈利报表-坐庄报表-时间': 'Time',
    '盈利报表-坐庄报表-坐庄ID': 'Banker ID',
    '盈利报表-坐庄报表-投注金额': 'Bet amount',
    '盈利报表-坐庄报表-有效金额': 'Valid amount',
    '盈利报表-坐庄报表-手续费': 'Admin charge',
    '盈利报表-坐庄报表-输赢': 'Win loss',
    '盈利报表-坐庄报表-笔数': 'transaction',
    '盈利报表-坐庄报表-总计': 'Total',
    // 盈利报表 - 坐庄报表 - 投注记录
    '盈利报表-坐庄报表-投注记录-坐庄投注记录': 'Banker betting record',
    '盈利报表-坐庄报表-投注记录-选择开始日期': 'Select start date',
    '盈利报表-坐庄报表-投注记录-选择结束日期': 'Select end date',
    '盈利报表-坐庄报表-投注记录-足球': 'Soccer',
    '盈利报表-坐庄报表-投注记录-下注金额': 'Bet amount：',
    '盈利报表-坐庄报表-投注记录-结果': 'result：',
    '盈利报表-坐庄报表-投注记录-总计': 'Total',
    '盈利报表-坐庄报表-投注记录-下注总计': 'Bet total：',
    '盈利报表-坐庄报表-投注记录-结果总计': 'Result total：',
    '盈利报表-坐庄报表-投注记录-返回顶部': 'Back to top',
    // 盈利报表 - 坐庄报表 - 详情
    '盈利报表-坐庄报表-详情-坐庄报表': 'Brokerage',
    '盈利报表-坐庄报表-详情-选择开始日期': 'Select start date',
    '盈利报表-坐庄报表-详情-选择结束日期': 'Select end date',
    '盈利报表-坐庄报表-详情-保证金额': 'Deposit Amount',
    '盈利报表-坐庄报表-详情-投注金额': 'Bet amount',
    '盈利报表-坐庄报表-详情-输赢': 'Win loss',
    '盈利报表-坐庄报表-详情-保证金': 'Deposit',
    '盈利报表-坐庄报表-详情-门票价': 'Minimum bet',
    '盈利报表-坐庄报表-详情-总投注金额': 'Total bet amount',
    '盈利报表-坐庄报表-详情-笔': 'transaction',
    '盈利报表-坐庄报表-详情-手续费': 'Admin charge',
    '盈利报表-坐庄报表-详情-玩家输赢': 'Win loss',
    '盈利报表-坐庄报表-详情-奖池手续费': 'Prize pool admin charge',
    '盈利报表-坐庄报表-详情-奖池余额': 'Prize pool balance',
    '盈利报表-坐庄报表-详情-奖池输赢': 'Prize pool win loss',

    // 盈利报表 - 投注报表
    '盈利报表-投注报表-选择周期': 'Select cycle',
    '盈利报表-投注报表-今日': 'Today',
    '盈利报表-投注报表-昨日': 'Yesterday',
    '盈利报表-投注报表-一周内': 'Within a week',
    '盈利报表-投注报表-一月内': 'Within one month',
    '盈利报表-投注报表-选择开始日期': 'Select start date',
    '盈利报表-投注报表-选择结束日期': 'Select end date',
    '盈利报表-投注报表-投注金额': 'Bet amount',
    '盈利报表-投注报表-有效金额': 'Valid amount',
    '盈利报表-投注报表-输赢': 'Win loss',
    '盈利报表-投注报表-日期': 'Date',
    // 盈利报表 - 投注报表 - 详情
    '盈利报表-投注报表-详情-选择时间': 'Select Time',
    '盈利报表-投注报表-详情-足球': 'Soccer',
    '盈利报表-投注报表-详情-下注金额': 'Bet amount：',
    '盈利报表-投注报表-详情-结果': 'Result：',
    '盈利报表-投注报表-详情-总计': 'Total',
    '盈利报表-投注报表-详情-下注总计': 'Bet total：',
    '盈利报表-投注报表-详情-结果总计': 'Result total：',
    '盈利报表-投注报表-详情-返回顶部': 'Back to top',

    // 盈利报表 - 合计报表
    '盈利报表-合计报表-开始日期': 'Start date',
    '盈利报表-合计报表-结束日期': 'End date',
    '盈利报表-合计报表-日期': 'Date',
    '盈利报表-合计报表-投注赢输': 'Bet amount',
    '盈利报表-合计报表-坐庄赢输': 'Amount in force',
    '盈利报表-合计报表-总计': 'Total',

    // 我的赛事
    '我的赛事-我的赛事': 'My event',
    '我的赛事-投注': 'Bet',
    '我的赛事-坐庄': 'Banker',
    '我的赛事-取消收藏': 'Unbookmark',
    '我的赛事-确认是否取消收藏当前比赛': 'Confirm whether to unbookmark the current contest？',
    '我的赛事-已取消收藏': 'Unbookmarked！',

    // 赛程
    '赛程-赛程': 'Schedule',
    '赛程-所有日期': 'All dates',
    '赛程-星期一': 'Monday.',
    '赛程-星期二': 'Tuesday.',
    '赛程-星期三': 'Wednesday.',
    '赛程-星期四': 'Thursday.',
    '赛程-星期五': 'Friday.',
    '赛程-星期六': 'Saturday.',
    '赛程-星期日': 'Sunday.',
    '赛程-投注': 'Bet',
    '赛程-坐庄': 'Banker',
    '赛程-收藏': 'Bookmark',
    '赛程-取消收藏': 'Unbookmark',
    '赛程-确认是否收藏当前比赛': 'Confirm whether to bookmark the current contest？',
    '赛程-收藏成功': 'Bookmarked successfully！',
    '赛程-确认是否取消收藏当前比赛': 'Confirm whether to unbookmark the current contest？',
    '赛程-已取消收藏': 'Unbookmarked！',

    // 注单
    '注单-注单': 'Bet',
    '注单-足球': 'Soccer',
    '注单-请输入下注金额': 'Please enter the bet amount',
    '注单-最大投注金额': 'Maximum bet amount',
    '注单-最小投注金额': 'Minimum bet amount',
    '注单-下注总额': 'Total bet amount',
    '注单-预估可赢': 'Expect to win',
    '注单-下注': 'Betting',
    '注单-下注中': 'Betting in progress...',
    '注单-下注成功': 'Bet successful',
    '注单-下注失败': 'Bet failed',
    '注单-确认': 'Confirm',

    // 坐庄记录
    '坐庄记录-坐庄记录': 'Banker record',
    '坐庄记录-普通': 'Normal',
    '坐庄记录-比赛': 'Competition',
    '坐庄记录-奖池总额': 'Total prize pool：',
    '坐庄记录-下注总额': 'Total bet amount：',
    '坐庄记录-笔': 'transaction',
    '坐庄记录-撤销': 'Cancel',
    '坐庄记录-确认是否撤销': 'Confirm revocation？',
    '坐庄记录-已撤销': 'Revoked',
    '坐庄记录-保证金': 'Deposit',
    '坐庄记录-最低投注': 'Minimum bet',
    '坐庄记录-总投注金额': 'Total bet',
    '坐庄记录-胜负平': '1X2',
    '坐庄记录-主': 'Home',
    '坐庄记录-和': 'Draw',
    '坐庄记录-客': 'Away',
    '坐庄记录-波胆': 'Correct score',
    '坐庄记录-让球': 'Handicap',
    '坐庄记录-大小球': 'Over/Under',
    '坐庄记录-大': 'Over',
    '坐庄记录-小': 'Under',

    // 投注记录
    '投注记录-投注记录': 'Betting record',
    '投注记录-普通': 'Normal',
    '投注记录-比赛': 'Competition',
    '投注记录-笔': 'transaction',
    '投注记录-投注金额': 'Bet amount：',
    '投注记录-结果': 'Result：',
    '投注记录-足球': 'Soccer',

    // 比赛列表
    '比赛列表-比赛列表': 'Match list',
    '比赛列表-投注': 'Bet',
    '比赛列表-坐庄': 'Banker',
    '比赛列表-收藏': 'Bookmark',
    '比赛列表-取消收藏': 'Unbookmark',
    '比赛列表-确认是否收藏当前比赛': 'Confirm whether to bookmark the current match？',
    '比赛列表-收藏成功': 'Bookmarked successfully ！',
    '比赛列表-确认是否取消收藏当前比赛': 'Confirm whether to unbookmark the current match？',
    '比赛列表-已取消收藏': 'Unbookmarked！',

    // 坐庄
    '坐庄-保证金额': 'Deposit Amount',
    '坐庄-最低投注': 'Minimum bet',
    '坐庄-创建': 'Create',
    '坐庄-编辑': 'Edit',
    '坐庄-删除': 'Delete',
    '坐庄-平局': 'Draw',
    '坐庄-和局': 'Draw',
    '坐庄-其他': 'Other',
    '坐庄-胜负平': '1X2',
    '坐庄-波胆': 'Correct score',
    '坐庄-让球': 'Handicap',
    '坐庄-大小球': 'Over/Under',
    '坐庄-确认': 'Confirm',
    '坐庄-请输入': 'Please enter',
    '坐庄-设置赔率': 'Set odds',
    '坐庄-请输入设置赔率': 'Please enter set odds！',
    '坐庄-胜': 'Win',
    '坐庄-让': 'Handicap',
    '坐庄-大': 'Over',
    '坐庄-小': 'Under',
    '坐庄-提交成功': 'Submit successfully！',

    // 投注列表
    '投注列表-投注列表': 'Betting list',
    '投注列表-胜负平': '1X2',
    '投注列表-主': 'Home',
    '投注列表-和': 'Draw',
    '投注列表-客': 'Away',
    '投注列表-波胆': 'Correct score',
    '投注列表-让球': 'Handicap',
    '投注列表-大小球': 'Over/Under',
    '投注列表-大': 'Over',
    '投注列表-小': 'Under',
    '投注列表-现货量': 'Available balance：',
    '投注列表-最低投注': 'Minimum bet：',
    '投注列表-投注': 'Bet',
    '投注列表-足球': 'Soccer',
    '投注列表-请输入下注金额': 'Please enter the bet amount',
    '投注列表-下注金额': 'Bet amount',
    '投注列表-设置金额': 'Set amount',
    '投注列表-可赢金额': 'Winnable amount',
    '投注列表-加到下注单': 'Add to the bet',
    '投注列表-下注': 'Betting',
    '投注列表-您已投注成功': 'Your bet has been successful！',
    '投注列表-确认': 'Confirm',
    '投注列表-提交成功': 'Submit successfully',

    // 比赛
    '比赛-锦标赛列表': 'Championship List',
    '比赛-奖池金额': 'Prize pool amount',
    '比赛-收益': 'Revenue',
    '比赛-排名': 'Ranking',
    '比赛-所有日期': 'All dates',
    '比赛-星期一': 'Monday.',
    '比赛-星期二': 'Tuesday.',
    '比赛-星期三': 'Wednesday.',
    '比赛-星期四': 'Thursday.',
    '比赛-星期五': 'Friday.',
    '比赛-星期六': 'Saturday.',
    '比赛-星期日': 'Sunday.',
    '比赛-投注': 'Bet',
    '比赛-坐庄': 'Banker',

    // 比赛 - 排名
    '比赛排名-排名': 'Ranking',

    // 比赛 - 坐庄
    '比赛坐庄-保证金额': 'Deposit Amount',
    '比赛坐庄-最低投注': 'Minimum bet',
    '比赛坐庄-创建': 'Create',
    '比赛坐庄-编辑': 'Edit',
    '比赛坐庄-删除': 'Delete',
    '比赛坐庄-平局': 'Draw',
    '比赛坐庄-和局': 'Draw',
    '比赛坐庄-胜负平': '1X2',
    '比赛坐庄-确认': 'Confirm',
    '比赛坐庄-请输入': 'Please enter',
    '比赛坐庄-设置赔率': 'Set odds',
    '比赛坐庄-请输入设置赔率': 'Please enter set odds！',
    '比赛坐庄-胜': 'Win',
    '比赛坐庄-提交成功': 'Submit successfully！',

    // 比赛 - 投注
    '比赛投注-投注列表': 'Betting list',
    '比赛投注-胜负平': '1X2',
    '比赛投注-主': 'Home',
    '比赛投注-和': 'Draw',
    '比赛投注-客': 'Away',
    '比赛投注-现货量': 'Available balance：',
    '比赛投注-最低投注': 'Minimum bet：',
    '比赛投注-投注': 'Bet',
    '比赛投注-足球': 'Soccer',
    '比赛投注-请输入下注金额': 'Please enter the bet amount',
    '比赛投注-下注金额': 'Bet amount',
    '比赛投注-设置金额': 'Set amount',
    '比赛投注-可赢金额': 'Winnable amount',
    '比赛投注-下注': 'Betting',
    '比赛投注-您已投注成功': 'Your bet has been successful！',
    '比赛投注-确认': 'Confirm',
    '比赛投注-提交成功': 'Submit successfully',
}
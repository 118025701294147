export default {
    '暂无数据': '暂无数据',
    '没有更多了': '没有更多了',
    'Dialog确认': '确认',
    'Dialog取消': '取消',
    '语言切换-简体版': '简体版',
    '语言切换-繁體版': '繁體版',
    '语言切换-ENGLISH': 'English',
    '语言切换-JAPANESE': 'Japanese',
    '其它': '其它',
    '锦标赛奖池': '锦标赛奖池',

    // 登录
    '登录-电子邮箱/用户名': '电子邮箱/用户名',
    '登录-请填写电子邮箱/用户名': '请填写电子邮箱/用户名',
    '登录-密码': '密码',
    '登录-请填写密码': '请填写密码',
    '登录-登入': '登入',
    '登录-去注册': '去注册',
    '登录-忘记密码': '忘记密码？',
    '登录-提示-登录中': '登录中...',
    '登录-我们推荐使用以下浏览器以获得最佳使用体验': '我们推荐使用以下浏览器以获得最佳使用体验',
    '登录-如果您在使用本网站遇到任何问题，请与我们联系': '如果您在使用本网站遇到任何问题，请与我们联系',

    // 注册
    '注册-登入': '登入',
    '注册-用户名': '用户名',
    '注册-请填写用户名': '请填写用户名',
    '注册-用户名格式错误': '用户名由6～16位字母或数字组成',
    '注册-密码': '密码',
    '注册-请填写密码': '请填写密码',
    '注册-密码由8~18位字符、数字、特殊符号组成': '密码由8~18位字符、数字、特殊符号组成',
    '注册-电子邮箱': '电子邮箱',
    '注册-请填写电子邮箱': '请填写电子邮箱',
    '注册-请输入正确的电子邮箱': '请输入正确的电子邮箱',
    '注册-邮箱验证码': '邮箱验证码',
    '注册-请填写邮箱验证码': '请填写邮箱验证码',
    '注册-发送验证码': '发送验证码',
    '注册-验证码发送成功': '发送成功！',
    '注册-姓名': '姓名',
    '注册-请填写姓名': '请填写姓名',
    '注册-邀请码': '邀请码（可选填）',
    '注册-注册': '注册',
    '注册-注册成功，即将跳转登录页面...': '注册成功，即将跳转登录页面...',
    '注册-我们推荐使用以下浏览器以获得最佳使用体验': '我们推荐使用以下浏览器以获得最佳使用体验',
    '注册-如果您在使用本网站遇到任何问题，请与我们联系': '如果您在使用本网站遇到任何问题，请与我们联系',

    // 忘记密码
    '忘记密码-忘记密码': '忘记密码',
    '忘记密码-您的密码必须根据以下规则:': '您的密码必须根据以下规则:',
    '忘记密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.密码由8~18位字符、数字、特殊符号组成。',
    '忘记密码-电子邮箱': '电子邮箱',
    '忘记密码-请填写电子邮箱': '请填写电子邮箱',
    '忘记密码-请输入正确的电子邮箱': '请输入正确的电子邮箱',
    '忘记密码-邮箱验证码': '邮箱验证码',
    '忘记密码-发送验证码': '发送验证码',
    '忘记密码-请填写邮箱验证码': '请填写邮箱验证码',
    '忘记密码-验证码发送成功': '验证码发送成功',
    '忘记密码-新密码': '新密码',
    '忘记密码-请填写新密码': '请填写新密码',
    '忘记密码-密码由8~18位字符、数字、特殊符号组成': '密码由8~18位字符、数字、特殊符号组成',
    '忘记密码-确认新密码': '确认新密码',
    '忘记密码-请填写确认新密码': '请填写确认新密码',
    '忘记密码-与新密码不一致': '与新密码不一致',
    '忘记密码-取消': '取消',
    '忘记密码-提交': '提交',
    '忘记密码-修改成功': '修改成功',

    // 顶部菜单
    '顶部菜单-今日': '今日',
    '顶部菜单-早盘': '早盘',
    '顶部菜单-团队': '团队',
    '顶部菜单-盈利报表': '盈利报表',
    '顶部菜单-锦标赛': '锦标赛',
    '顶部菜单-余额': '余额',
    '顶部菜单-提现': '提现',
    '顶部菜单-邀请码': '邀请码',
    '顶部菜单-数字钱包': '数字钱包',
    '顶部菜单-子-钱包充值': '钱包充值',
    '顶部菜单-子-钱包提现': '钱包提现',
    '顶部菜单-子-钱包记录': '钱包记录',
    '顶部菜单-子-兑换': '兑换',
    '顶部菜单-子-UU消费记录': 'UU 消费记录',
    '顶部菜单-子-TT消费记录': 'TT 消费记录',
    '顶部菜单-修改密码': '修改密码',
    '顶部菜单-修改邮箱绑定': '修改邮箱绑定',
    '顶部菜单-公告': '公告',
    '顶部菜单-联系我们': '联系我们',
    '顶部菜单-玩法规则': '玩法规则',
    '顶部菜单-语言切换': '语言切换',
    '顶部菜单-退出': '退出',

    // 底部菜单
    '底部菜单-我的赛事': '我的赛事',
    '底部菜单-赛程': '赛程',
    '底部菜单-注单': '注单',
    '底部菜单-坐庄记录': '坐庄记录',
    '底部菜单-投注记录': '投注记录',

    // 邀请
    '邀请-邀请好友立即获得高额收益返佣': '邀请好友立即获得高额收益返佣',
    '邀请-邀请码': '邀请码',
    '邀请-复制链接': '复制链接',
    '邀请-复制成功': '复制成功',

    // 钱包 - 钱包充值
    '钱包-钱包充值-钱包充值': '钱包充值',
    '钱包-钱包充值-您的存款地址': '您的存款地址：',
    '钱包-钱包充值-复制': '复制',
    '钱包-钱包充值-复制成功': '复制成功！',
    '钱包-钱包充值-最低存款100USDT，较低的金额可能不会被记入，或被退款。': '最低存款100USDT，较低的金额可能不会被记入，或被退款。',

    // 钱包 - 钱包提现
    '钱包-钱包提现-提现': '钱包提现',
    '钱包-钱包提现-选择币种': '选择币种',
    '钱包-钱包提现-选择网络': '选择网络',
    '钱包-钱包提现-您的提款地址': '您的提款地址：',
    '钱包-钱包提现-请输入提款地址': '请输入提款地址：',
    '钱包-钱包提现-数量': '数量',
    '钱包-钱包提现-请输入数量': '请输入数量',
    '钱包-钱包提现-余额': '余额',
    '钱包-钱包提现-最低存款100USDT，较低的金额可能不会被记入，或被退款。': '最低存款100USDT，较低的金额可能不会被记入，或被退款。',
    '钱包-钱包提现-确认': '确认',
    '钱包-钱包提现-网络交易需要0-2小时，且网络确认不包括在内': '网络交易需要0-2小时，且网络确认不包括在内',
    '钱包-钱包提现-提交成功': '提交成功！',

    // 钱包 - 钱包记录
    '钱包-钱包记录-钱包记录': '钱包记录',
    '钱包-钱包记录-选择类型': '选择类型',
    '钱包-钱包记录-全部': '全部',
    '钱包-钱包记录-充值': '充值',
    '钱包-钱包记录-提现': '提现',
    '钱包-钱包记录-兑换': '兑换',
    '钱包-钱包记录-选择开始日期': '选择开始日期',
    '钱包-钱包记录-选择结束日期': '选择结束日期',
    '钱包-钱包记录-币种': '币种',
    '钱包-钱包记录-网络': '网络',
    '钱包-钱包记录-类型': '类型',
    '钱包-钱包记录-金额': '金额',
    '钱包-钱包记录-手续费': '手续费',
    '钱包-钱包记录-状态': '状态',
    '钱包-钱包记录-确认中': '确认中',
    '钱包-钱包记录-已确认': '已确认',
    '钱包-钱包记录-充值地址': '充值地址：',
    '钱包-钱包记录-充值时间': '充值时间：',
    '钱包-钱包记录-提现地址': '提现地址：',
    '钱包-钱包记录-到账时间': '到账时间：',
    '钱包-钱包记录-描述': '描述：',
    '钱包-钱包记录-时间': '时间：',

    // 钱包 - 兑换
    '钱包-兑换-兑换': '兑换',
    '钱包-兑换-USDT兑换UU': 'USDT兑换UU',
    '钱包-兑换-UU兑换USDT': 'UU兑换USDT',
    '钱包-兑换-USDT兑换TT': 'USDT兑换TT',
    '钱包-兑换-TT兑换USDT': 'TT兑换USDT',
    '钱包-兑换-请输入数量': '请输入数量！',
    '钱包-兑换-余额': '余额：',
    '钱包-兑换-提交成功': '提交成功',

    // 钱包 - UU消费记录
    '钱包-UU消费记录-消费记录': 'UU 消费记录',
    '钱包-UU消费记录-选择类型': '选择类型',
    '钱包-UU消费记录-选择开始日期': '选择开始日期',
    '钱包-UU消费记录-选择结束日期': '选择结束日期',
    '钱包-UU消费记录-时间': '时间',
    '钱包-UU消费记录-类型': '类型',
    '钱包-UU消费记录-金额': '金额',
    '钱包-UU消费记录-余额': '余额',
    '钱包-UU消费记录-描述': '描述：',
    '钱包-UU消费记录-订单': '订单：',

    // 钱包 - TT消费记录
    '钱包-TT消费记录-消费记录': 'TT 消费记录',
    '钱包-TT消费记录-选择类型': '选择类型',
    '钱包-TT消费记录-选择开始日期': '选择开始日期',
    '钱包-TT消费记录-选择结束日期': '选择结束日期',
    '钱包-TT消费记录-时间': '时间',
    '钱包-TT消费记录-类型': '类型',
    '钱包-TT消费记录-金额': '金额',
    '钱包-TT消费记录-余额': '余额',
    '钱包-TT消费记录-描述': '描述：',
    '钱包-TT消费记录-订单': '订单：',

    // 修改密码
    '修改密码-修改密码': '修改密码',
    '修改密码-您的密码必须根据以下规则:': '您的密码必须根据以下规则:',
    '修改密码-1.密码由8~18位字符、数字、特殊符号组成。': '1.密码由8~18位字符、数字、特殊符号组成。',
    '修改密码-旧密码': '旧密码',
    '修改密码-请填写旧密码': '请填写旧密码',
    '修改密码-新密码': '新密码',
    '修改密码-请填写新密码': '请填写新密码',
    '修改密码-密码由8~18位字符、数字、特殊符号组成': '密码由8~18位字符、数字、特殊符号组成',
    '修改密码-取消': '取消',
    '修改密码-提交': '提交',
    '修改密码-修改成功': '修改成功！',

    // 修改绑定邮箱
    '修改绑定邮箱-修改绑定邮箱': '修改绑定邮箱',
    '修改绑定邮箱-您的邮箱必须根据以下规则:': '您的邮箱必须根据以下规则:',
    '修改绑定邮箱-1.验证码有效期 10min。': '1.验证码有效期 10min。',
    '修改绑定邮箱-2.每个验证码只能验证一次。': '2.每个验证码只能验证一次。',
    '修改绑定邮箱-旧邮箱': '旧邮箱',
    '修改绑定邮箱-新的邮箱': '新的邮箱',
    '修改绑定邮箱-请填写新的邮箱': '请填写新的邮箱',
    '修改绑定邮箱-请输入正确的电子邮箱': '请输入正确的电子邮箱',
    '修改绑定邮箱-邮箱验证码': '邮箱验证码',
    '修改绑定邮箱-请填写邮箱验证码': '请填写邮箱验证码',
    '修改绑定邮箱-发送验证码': '发送验证码',
    '修改绑定邮箱-验证码发送成功': '验证码发送成功',
    '修改绑定邮箱-取消': '取消',
    '修改绑定邮箱-提交': '提交',
    '修改绑定邮箱-修改成功': '修改成功！',

    // 公告
    '公告-公告': '公告',
    '公告-一般': '一般',
    '公告-重要': '重要',
    '公告-个人': '个人',
    '公告-全部': '全部',
    '公告-今日': '今日',
    '公告-昨日': '昨日',

    // 联系我们
    '联系我们-联系我们': '联系我们',
    '联系我们-联系邮箱': '联系邮箱',

    // 玩法规则
    '玩法规则-玩法规则': '玩法规则',

    // 首页
    '首页-今日赛事': '今日赛事',
    '首页-早盘赛事': '早盘赛事',

    // 今日
    '今日-热门赛事': '热门赛事',

    // 早盘
    '早盘-热门赛事': '热门赛事',
    '早盘-所有日期': '所有日期',
    '早盘-星期一': '星期一',
    '早盘-星期二': '星期二',
    '早盘-星期三': '星期三',
    '早盘-星期四': '星期四',
    '早盘-星期五': '星期五',
    '早盘-星期六': '星期六',
    '早盘-星期日': '星期日',

    // 团队
    '团队-返佣总额': '返佣总额',
    '团队-团队人数': '团队人数',
    '团队-人': '人',
    '团队-一级': '一级',
    '团队-二级': '二级',
    '团队-三级': '三级',
    '团队-选择开始日期': '选择开始日期',
    '团队-选择结束日期': '选择结束日期',
    '团队-会员名称': '会员名称',
    '团队-推广返佣': '推广返佣',
    '团队-推广分成': '推广分成',
    '团队-详情': '详情',
    // 团队详情
    '团队详情 - 团队详情': '团队详情',
    '团队详情-选择开始日期': '选择开始日期',
    '团队详情-选择结束日期': '选择结束日期',
    '团队详情-会员ID': '会员ID',
    '团队详情-类型': '类型',
    '团队详情-推广分成': '推广分成',
    '团队详情-推广返佣': '推广返佣',

    // 盈利报表
    '盈利报表-选择': '选择',
    '盈利报表-选择-普通': '普通盈利报表',
    '盈利报表-选择-比赛': '比赛盈利报表',
    '盈利报表-坐庄报表': '坐庄报表',
    '盈利报表-投注报表': '投注报表',
    '盈利报表-合计报表': '合计报表',
    // 盈利报表 - 坐庄报表
    '盈利报表-坐庄报表-选择周期': '选择周期',
    '盈利报表-坐庄报表-选择开始日期': '选择开始日期',
    '盈利报表-坐庄报表-选择结束日期': '选择结束日期',
    '盈利报表-坐庄报表-时间': '时间',
    '盈利报表-坐庄报表-坐庄ID': '坐庄ID',
    '盈利报表-坐庄报表-投注金额': '投注金额',
    '盈利报表-坐庄报表-有效金额': '有效金额',
    '盈利报表-坐庄报表-手续费': '手续费',
    '盈利报表-坐庄报表-输赢': '输赢',
    '盈利报表-坐庄报表-笔数': '笔数',
    '盈利报表-坐庄报表-总计': '总计',
    // 盈利报表 - 坐庄报表 - 投注记录
    '盈利报表-坐庄报表-投注记录-坐庄投注记录': '坐庄投注记录',
    '盈利报表-坐庄报表-投注记录-选择开始日期': '选择开始日期',
    '盈利报表-坐庄报表-投注记录-选择结束日期': '选择结束日期',
    '盈利报表-坐庄报表-投注记录-足球': '足球',
    '盈利报表-坐庄报表-投注记录-下注金额': '下注金额：',
    '盈利报表-坐庄报表-投注记录-结果': '结果：',
    '盈利报表-坐庄报表-投注记录-总计': '总计',
    '盈利报表-坐庄报表-投注记录-下注总计': '下注总计：',
    '盈利报表-坐庄报表-投注记录-结果总计': '结果总计：',
    '盈利报表-坐庄报表-投注记录-返回顶部': '返回顶部',
    // 盈利报表 - 坐庄报表 - 详情
    '盈利报表-坐庄报表-详情-坐庄报表': '坐庄报表',
    '盈利报表-坐庄报表-详情-选择开始日期': '选择开始日期',
    '盈利报表-坐庄报表-详情-选择结束日期': '选择结束日期',
    '盈利报表-坐庄报表-详情-保证金额': '保证金额',
    '盈利报表-坐庄报表-详情-投注金额': '投注金额',
    '盈利报表-坐庄报表-详情-输赢': '输赢',
    '盈利报表-坐庄报表-详情-保证金': '保证金',
    '盈利报表-坐庄报表-详情-门票价': '门票价',
    '盈利报表-坐庄报表-详情-总投注金额': '总投注金额',
    '盈利报表-坐庄报表-详情-笔': '笔',
    '盈利报表-坐庄报表-详情-玩家输赢': '玩家输赢',
    '盈利报表-坐庄报表-详情-奖池手续费': '奖池手续费',
    '盈利报表-坐庄报表-详情-奖池余额': '奖池余额',
    '盈利报表-坐庄报表-详情-奖池输赢': '奖池输赢',

    // 盈利报表 - 投注报表
    '盈利报表-投注报表-选择周期': '选择周期',
    '盈利报表-投注报表-今日': '今日',
    '盈利报表-投注报表-昨日': '昨日',
    '盈利报表-投注报表-一周内': '一周内',
    '盈利报表-投注报表-一月内': '一月内',
    '盈利报表-投注报表-选择开始日期': '选择开始日期',
    '盈利报表-投注报表-选择结束日期': '选择结束日期',
    '盈利报表-投注报表-投注金额': '投注金额',
    '盈利报表-投注报表-有效金额': '有效金额',
    '盈利报表-投注报表-输赢': '输赢',
    '盈利报表-投注报表-日期': '日期',
    // 盈利报表 - 投注报表 - 详情
    '盈利报表-投注报表-详情-选择时间': '选择时间',
    '盈利报表-投注报表-详情-足球': '足球',
    '盈利报表-投注报表-详情-下注金额': '下注金额：',
    '盈利报表-投注报表-详情-结果': '结果：',
    '盈利报表-投注报表-详情-总计': '总计',
    '盈利报表-投注报表-详情-下注总计': '下注总计：',
    '盈利报表-投注报表-详情-结果总计': '结果总计：',
    '盈利报表-投注报表-详情-返回顶部': '返回顶部',

    // 盈利报表 - 合计报表
    '盈利报表-合计报表-开始日期': '开始日期',
    '盈利报表-合计报表-结束日期': '结束日期',
    '盈利报表-合计报表-日期': '日期',
    '盈利报表-合计报表-投注赢输': '投注赢输',
    '盈利报表-合计报表-坐庄赢输': '坐庄赢输',
    '盈利报表-合计报表-总计': '总计',

    // 我的赛事
    '我的赛事-我的赛事': '我的赛事',
    '我的赛事-投注': '投注',
    '我的赛事-坐庄': '坐庄',
    '我的赛事-取消收藏': '取消收藏',
    '我的赛事-确认是否取消收藏当前比赛': '确认是否取消收藏当前比赛？',
    '我的赛事-已取消收藏': '已取消收藏！',

    // 赛程
    '赛程-赛程': '赛程',
    '赛程-所有日期': '所有日期',
    '赛程-星期一': '星期一',
    '赛程-星期二': '星期二',
    '赛程-星期三': '星期三',
    '赛程-星期四': '星期四',
    '赛程-星期五': '星期五',
    '赛程-星期六': '星期六',
    '赛程-星期日': '星期日',
    '赛程-投注': '投注',
    '赛程-坐庄': '坐庄',
    '赛程-收藏': '收藏',
    '赛程-取消收藏': '取消收藏',
    '赛程-确认是否收藏当前比赛': '确认是否收藏当前比赛？',
    '赛程-收藏成功': '收藏成功！',
    '赛程-确认是否取消收藏当前比赛': '确认是否取消收藏当前比赛？',
    '赛程-已取消收藏': '已取消收藏！',

    // 注单
    '注单-注单': '注单',
    '注单-足球': '足球',
    '注单-请输入下注金额': '请输入下注金额',
    '注单-最大投注金额': '最大投注金额',
    '注单-最小投注金额': '最小投注金额',
    '注单-下注总额': '下注总额',
    '注单-预估可赢': '预估可赢',
    '注单-下注': '下注',
    '注单-下注中': '下注中...',
    '注单-下注成功': '下注成功',
    '注单-下注失败': '下注失败',
    '注单-确认': '确认',

    // 坐庄记录
    '坐庄记录-坐庄记录': '坐庄记录',
    '坐庄记录-普通': '普通',
    '坐庄记录-比赛': '比赛',
    '坐庄记录-奖池总额': '奖池总额：',
    '坐庄记录-下注总额': '下注总额：',
    '坐庄记录-笔': '笔',
    '坐庄记录-撤销': '撤销',
    '坐庄记录-确认是否撤销': '确认是否撤销？',
    '坐庄记录-已撤销': '已撤销',
    '坐庄记录-保证金': '保证金',
    '坐庄记录-最低投注': '最低投注',
    '坐庄记录-总投注金额': '总投注金额',
    '坐庄记录-胜负平': '胜负平',
    '坐庄记录-主': '主',
    '坐庄记录-和': '和',
    '坐庄记录-客': '客',
    '坐庄记录-波胆': '波胆',
    '坐庄记录-让球': '让球',
    '坐庄记录-大小球': '大小球',
    '坐庄记录-大': '大',
    '坐庄记录-小': '小',

    // 投注记录
    '投注记录-投注记录': '投注记录',
    '投注记录-普通': '普通',
    '投注记录-比赛': '比赛',
    '投注记录-笔': '笔',
    '投注记录-投注金额': '投注金额：',
    '投注记录-结果': '结果：',
    '投注记录-足球': '足球',

    // 比赛列表
    '比赛列表-比赛列表': '比赛列表',
    '比赛列表-投注': '投注',
    '比赛列表-坐庄': '坐庄',
    '比赛列表-收藏': '收藏',
    '比赛列表-取消收藏': '取消收藏',
    '比赛列表-确认是否收藏当前比赛': '确认是否收藏当前比赛？',
    '比赛列表-收藏成功': '收藏成功！',
    '比赛列表-确认是否取消收藏当前比赛': '确认是否取消收藏当前比赛？',
    '比赛列表-已取消收藏': '已取消收藏！',

    // 坐庄
    '坐庄-保证金额': '保证金额',
    '坐庄-最低投注': '最低投注',
    '坐庄-创建': '创建',
    '坐庄-编辑': '编辑',
    '坐庄-删除': '删除',
    '坐庄-平局': '平局',
    '坐庄-和局': '和局',
    '坐庄-胜负平': '胜负平',
    '坐庄-波胆': '波胆',
    '坐庄-让球': '让球',
    '坐庄-大小球': '大小球',
    '坐庄-确认': '确认',
    '坐庄-请输入': '请输入',
    '坐庄-设置赔率': '设置赔率',
    '坐庄-请输入设置赔率': '请输入设置赔率！',
    '坐庄-胜': '胜',
    '坐庄-让': '让',
    '坐庄-大': '大',
    '坐庄-小': '小',
    '坐庄-提交成功': '提交成功！',

    // 投注列表
    '投注列表-投注列表': '投注列表',
    '投注列表-胜负平': '胜负平',
    '投注列表-主': '主',
    '投注列表-和': '和',
    '投注列表-客': '客',
    '投注列表-波胆': '波胆',
    '投注列表-让球': '让球',
    '投注列表-大小球': '大小球',
    '投注列表-大': '大',
    '投注列表-小': '小',
    '投注列表-现货量': '现货量：',
    '投注列表-最低投注': '最低投注：',
    '投注列表-投注': '投注',
    '投注列表-足球': '足球',
    '投注列表-请输入下注金额': '请输入下注金额',
    '投注列表-下注金额': '下注金额',
    '投注列表-设置金额': '设置金额',
    '投注列表-可赢金额': '可赢金额',
    '投注列表-加到下注单': '加到下注单',
    '投注列表-下注': '下注',
    '投注列表-您已投注成功': '您已投注成功！',
    '投注列表-确认': '确认',
    '投注列表-提交成功': '提交成功',

    // 比赛
    '比赛-锦标赛列表': '锦标赛列表',
    '比赛-奖池金额': '奖池金额',
    '比赛-收益': '收益',
    '比赛-排名': '排名',
    '比赛-所有日期': '所有日期',
    '比赛-星期一': '星期一',
    '比赛-星期二': '星期二',
    '比赛-星期三': '星期三',
    '比赛-星期四': '星期四',
    '比赛-星期五': '星期五',
    '比赛-星期六': '星期六',
    '比赛-星期日': '星期日',
    '比赛-投注': '投注',
    '比赛-坐庄': '坐庄',

    // 比赛 - 排名
    '比赛排名-排名': '排名',

    // 比赛 - 坐庄
    '比赛坐庄-保证金额': '保证金额',
    '比赛坐庄-最低投注': '最低投注',
    '比赛坐庄-创建': '创建',
    '比赛坐庄-编辑': '编辑',
    '比赛坐庄-删除': '删除',
    '比赛坐庄-平局': '平局',
    '比赛坐庄-和局': '和局',
    '比赛坐庄-胜负平': '胜负平',
    '比赛坐庄-确认': '确认',
    '比赛坐庄-请输入': '请输入',
    '比赛坐庄-设置赔率': '设置赔率',
    '比赛坐庄-请输入设置赔率': '请输入设置赔率！',
    '比赛坐庄-胜': '胜',
    '比赛坐庄-提交成功': '提交成功！',

    // 比赛 - 投注
    '比赛投注-投注列表': '投注列表',
    '比赛投注-胜负平': '胜负平',
    '比赛投注-主': '主',
    '比赛投注-和': '和',
    '比赛投注-客': '客',
    '比赛投注-现货量': '现货量：',
    '比赛投注-最低投注': '最低投注：',
    '比赛投注-投注': '投注',
    '比赛投注-足球': '足球',
    '比赛投注-请输入下注金额': '请输入下注金额',
    '比赛投注-下注金额': '下注金额',
    '比赛投注-设置金额': '设置金额',
    '比赛投注-可赢金额': '可赢金额',
    '比赛投注-下注': '下注',
    '比赛投注-您已投注成功': '您已投注成功！',
    '比赛投注-确认': '确认',
    '比赛投注-提交成功': '提交成功',
}
import { Toast } from "vant";
import axios from "axios";
import Router from "@/router";
import store from "@/store";
import StorageHandler from "./storageHandler";
let storageHandler = new StorageHandler();

axios.defaults.baseURL = '/api'
axios.defaults.timeout = 1000 * 60 * 3;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    config => {
        config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=utf-8";
        config.headers["language"] = store.getters.language;
        config.headers["token"] = storageHandler.getSessionStorage("token") || '';
        return config;
    },
    error => {
        console.log(`err${error}`);
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    res => {
        if (res.data.code == 2 || res.data.code == 5) {
            Toast.fail(`${res.data.msg}`);
            setTimeout(() => {
                Router.push('/');
                storageHandler.clearLocalStorage();
                storageHandler.clearSessionStorage();
            }, 2000);
        }
        if (res.data.code != 0 && !res.request.responseURL.includes('/get-wallet-balance')) {
            Toast.fail(`${res.data.msg}`);
            return Promise.reject(res.data.msg);
        }
        if (res.data.code == 0) {
            if (res.data.data.token && !res.request.responseURL.includes('/get-wallet-balance')) {
                storageHandler.setSessionStorage('token', res.data.data.token);
            }
            return res.data;
        }
    },
    error => {
        if (error.config.url != '/get-wallet-balance') {
            Toast.fail(`${error}`);
        }
        return Promise.reject(error);
    }
)

export default axios;

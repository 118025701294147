export default [
    {
        path: '/',
        redirect: 'login'
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: '登录',
        },
        component: () => import("@/views/home/login.vue")
    },
    {
        path: "/register",
        name: "register",
        meta: {
            title: '注册',
        },
        component: () => import("@/views/home/register.vue")
    },
    {
        path: "/forgotPassword",
        name: "forgotPassword",
        meta: {
            title: '忘记密码',
        },
        component: () => import("@/views/home/forgotPassword.vue")
    }
]